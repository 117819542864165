import React, { useState, useEffect, useRef } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	Select,
	Upload,
	InputNumber,
	Affix,
	Modal,
	Table,
	message,
	Row,
	Col,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	fishList,
	uploadfile,
	deleteFile,
	addCommodity,
  updateCommodity
} from '@/https/GoodsManage'
import E from 'wangeditor'

export default function Page() {
	const { TextArea } = Input
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const [supplierForm] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
	const detailData = useSelector((state) => state.GoodsManage.detail)
	const districtData = useSelector((state) => state.District.districtData)
  const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxCover = 10
	const editor = useRef(null)

	const [coverList, setCoverList] = useState([])
	const [bannerList, setBannerList] = useState([])
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')
	const [supplierData, setSupplierData] = useState('')
	const [selectedRows, setSelectedRows] = useState('')
	const [selectedSupplier, setSelectedSupplier] = useState('')
	const [provinceList, setProvinceList] = useState('')
	const [cityList, setCityList] = useState('')
	const [areaList, setAreaList] = useState('')

	const onFinish = async () => {
		const formData = await form.validateFields()
		const content = editor.current.txt.html()
		let coverData = [],
			bannerPictureUrlData = []
		formData?.cover?.fileList &&
			formData.cover.fileList.map((item) => {
				coverData.push(item.url)
				return true
			})
		formData?.bannerPictureUrl?.fileList &&
			formData.bannerPictureUrl.fileList.map((item) => {
				bannerPictureUrlData.push(item.url)
				return true
			})
		const params = {
			...formData,
			detail: content,
			cover: coverData.join(','),
			bannerPictureUrl: bannerPictureUrlData.join(','),
			supplierId: selectedSupplier.id,
		}
    let postFun;
    if(operate === 'add'){
      postFun = addCommodity
    }else{
      postFun = updateCommodity
      params.id = id*1
    }
    const { data } = await postFun(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleCoverChange = ({ fileList }) => {
		setCoverList(fileList)
	}

	const handleBannerChange = ({ fileList }) => {
		setBannerList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setCoverList([...coverList])
			message.success('上传成功')
		} else {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setCoverList([...coverList])
			message.error(data.message)
		}
	}

	const upLoadBannerFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			bannerList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setBannerList([...bannerList])
			message.success('上传成功')
		} else {
			bannerList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setBannerList([...bannerList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
		const { url, status } = file
		if (status === 'error') {
			return true
		}
		const { data } = await deleteFile({ filePath: url })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

	// 富文本
	useEffect(() => {
		editor.current = new E('#detail_html')
		const editorDetail = editor.current
		editorDetail.config.zIndex = 100
		// 上传图片
		editorDetail.config.uploadImgMaxLength = 1 // 一次最多上传 5 个图片
		editorDetail.config.customUploadImg = function (resultFiles, insertImgFn) {
			// resultFiles 是 input 中选中的文件列表
			// insertImgFn 是获取图片 url 后，插入到编辑器的方法
			let formData = new FormData()
			formData.append('file', resultFiles[0])
			uploadfile(formData).then(({data}) => {
				if (data.code === '00000') {
					insertImgFn(data.data)
					message.success('上传成功')
				} else {
					message.error(data.message)
				}
			}).catch(res => {
				console.log('res :>> ', res);
				message.error('上传失败，代码或者接口报错')
			})
			// 上传图片，返回结果，将图片插入到编辑器中
			// insertImgFn(imgUrl)
		}
		editorDetail.create()
    if(detailData) editor.current.txt.html(detailData.detail) 
    // 禁用
    if(operate === 'look') editorDetail.disable();
		return () => {
			// 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
			editorDetail.destroy()
		}
	}, [detailData, operate])

  useEffect(() => {
    // 图片处理,供应商数据
    const trimData = () => {
      if(detailData?.cover){
        const coverArr = detailData.cover.split(',')
        let converForm = []
        coverArr.map((item, i)=>{
          converForm.push({
            uid: i,
            url: item,
            status: 'done'
          })
          return true
        })
        setCoverList(converForm)
      }
      if(detailData?.bannerPictureUrl){
        const bannerArr = detailData.bannerPictureUrl.split(',')
        let bannerForm = []
        bannerArr.map((item, i)=>{
          bannerForm.push({
            uid: i,
            url: item,
            status: 'done'
          })
          return true
        })
        setBannerList(bannerForm)
      }
    }
    trimData()
  }, [detailData])

  // 图片表单数据处理
  const onNeatenData = (data) => {
    let converForm = [], bannerForm = []
    if(data.cover){
      const coverArr = data.cover.split(',')
      coverArr.map((item, i)=>{
        converForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
    }
    if(data.bannerPictureUrl){
      const bannerArr = data.bannerPictureUrl.split(',')
      bannerArr.map((item, i)=>{
        bannerForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
    }
    console.log({
      ...data,
      cover: {fileList: converForm},
      bannerPictureUrl: {fileList: bannerForm}
    })
    return {
      ...data,
      cover: {fileList: converForm},
      bannerPictureUrl: {fileList: bannerForm}
    }
  }

	// 选择供应商
	const showModal = () => {
    supplierForm.resetFields()
		setIsModalVisible(true)
		getFishList({provinceId: 1922, cityId: 1946})
	}

	useEffect(() => {
		if(districtData){
			setProvinceList(districtData)
		}
	}, [districtData])

	// 更换省份
	const handleProvinceChange = (id, item) => {
		if(id){
			const { key } = item
			setCityList(provinceList[key].cityList)
			form.setFieldsValue({cityId: '', areaId: ''})
		}
	}

	const handleCityChange = (id, item) => {
		if(id){
			const { key } = item
			setAreaList(cityList[key].areaList)
			form.setFieldsValue({areaId: ''})
		}
	}

	// 供应商列表
  const getFishList = (param) => {
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    fishList(params).then(({ data }) => {
			if (data.code === '00000') {
				setSupplierData(data.data)
			}
		})
  }

  // 供应商查询
  const onFishFinish = (values) => {
    getFishList(values)
  }

	useEffect(() => {
		if(provinceList && provinceList.length && isModalVisible){
			const checkPronce = provinceList.filter(info => info.id === 1922)
			setCityList(checkPronce[0].cityList)
			supplierForm.setFieldsValue({provinceId: 1922, cityId: 1946})
		}
	}, [supplierForm, provinceList, isModalVisible])

  /**
	 * 分页
	 * @param page
	 */
	const handleFishChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getFishList(data)
	}

	const handleOk = () => {
		setSelectedSupplier(selectedRows[0])
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setSelectedRows([selectedSupplier])
		setIsModalVisible(false)
	}

	// 选择供应商
	const rowSelection = {
		selectedRowKeys: selectedRows[0]?.id ? [selectedRows[0].id] : '',
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows)
		},
	}

	// 供应商
	const columns = [
		{
			title: '供应商名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '省份',
			dataIndex: 'provinceName',
			key: 'provinceName',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '市',
			dataIndex: 'cityName',
			key: 'cityName',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '电话',
			dataIndex: 'phone',
			key: 'phone',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '收费状态',
			dataIndex: 'payType',
			key: 'payType',
			render: (d) => <span>{d === 0 ? '计时' : d === 1 ? '记重' : d === 2 ? '混合' : d === 3 ? '套餐' : '免费'}</span>,
		},
		{
			title: '类别',
			dataIndex: 'type',
			key: 'type',
			render: (d) => <span>{d === 0 ? '钓场' : '渔具店'}</span>,
		}
	]

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => window.history.back()}
					title={
						operate === 'add'
							? '新增'
							: operate === 'edit'
							? '编辑'
							: '查看'
					}
					extra={[
						operate !== 'look' && <Button type="primary" onClick={onFinish}>
							保存
						</Button>
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form
					labelWrap
					{...layout}
					form={form}
					initialValues={
						operate === 'add'
							? { type: 0, exchangeType: 1 }
							: onNeatenData(detailData)
					}
				>
					<Form.Item
						name="name"
						label="商品名称"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="type" label="类别">
						<Select disabled={operate === 'look' ? true : false}>
							<Select.Option value={0}>虚拟</Select.Option>
							<Select.Option value={1}>实物</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="cover" label="商品图片地址">
						<Upload
              disabled={operate === 'look' ? true : false}
							customRequest={upLoadFile}
							listType="picture-card"
							fileList={coverList}
							onPreview={handlePreview}
							onChange={handleCoverChange}
							onRemove={removeFile}
						>
							{coverList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item name="bannerPictureUrl" label="商品banner图">
						<Upload
              disabled={operate === 'look' ? true : false}
							customRequest={upLoadBannerFile}
							listType="picture-card"
							fileList={bannerList}
							onPreview={handlePreview}
							onChange={handleBannerChange}
							onRemove={removeFile}
						>
							{bannerList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item name="unit" label="单位">
						<Input disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="enterPrice" label="进价">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="sellPrice" label="售价">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="bazaarPrice" label="市场价">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="total" label="商品数量">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="inventory" label="库存">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="detail" label="详情">
						<div id="detail_html"></div>
					</Form.Item>
					<Form.Item name="useRule" label="使用规则">
						<TextArea rows={4} disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="priceExplain" label="价格说明">
						<TextArea rows={4} disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="supplier" label="供应商">
						<div>
							<Button type="primary" onClick={showModal} disabled={operate === 'look' ? true : false}>
								选择供应商
							</Button>
							<span className="ml10">
								{selectedSupplier.name || '无'}
							</span>
						</div>
					</Form.Item>
					<Form.Item name="version" label="商品版本号">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
				</Form>
			</div>
			<Modal
				title="供应商"
				centered
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1000}
			>
				<Form
					form={supplierForm}
					className="public_search_box"
					onFinish={onFishFinish}
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item name={`name`}>
								<Input placeholder="供应商名称" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="serviceType" label="类别" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									<Select.Option value={0}>钓场</Select.Option>
									<Select.Option value={1}>渔具店</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="type" label="类型" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									<Select.Option value={0}>黑坑</Select.Option>
									<Select.Option value={1}>野钓</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="provinceId" label="省" initialValue="">
								<Select onChange={handleProvinceChange}>
									<Select.Option value="">全国</Select.Option>
									{
										provinceList && provinceList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="cityId" label="市" initialValue="">
								<Select onChange={handleCityChange}>
									<Select.Option value="">全部</Select.Option>
									{
										cityList && cityList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="areaId" label="区" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									{
										areaList && areaList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Button type="primary" htmlType="submit">
								查询
							</Button>
							<Button
								style={{ margin: '0 8px' }}
								onClick={() => {
									supplierForm.resetFields()
								}}
							>
								清空
							</Button>
						</Col>
					</Row>
				</Form>
				<Table
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						type: 'radio',
						...rowSelection,
					}}
					dataSource={supplierData.list}
					columns={columns}
					scroll={{ x: 'max-content'}}
					pagination={{
						current: supplierData.current*1,
						total: supplierData.total*1,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleFishChangePage,
					}}
				/>
			</Modal>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	)
}
