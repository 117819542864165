import React, { useState, useEffect, useCallback } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	InputNumber,
	Select,
	Upload,
	message,
	Modal,
	Affix,
	Row,
	Col,
	Table,
	DatePicker,
  Collapse,
  Space,
  Divider
} from 'antd'
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	uploadfile,
	deleteFile,
	fishList,
  commodityList,
	addOrUpdateLottery,
	lotteryDetails
} from '@/https/OrbManage'
import moment from 'moment'

export default function Page() {
	const { TextArea } = Input
	const { RangePicker } = DatePicker
  const { Panel } = Collapse
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const [commodityForm] = Form.useForm()
	const [fishForm] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
  const districtData = useSelector((state) => state.District.districtData)
  const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxCover = 10

  const [detailData, setDetailData] = useState('')
	const [coverList, setCoverList] = useState([])
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')
	const [isFishModalVisible, setIsFishModalVisible] = useState(false)
	const [fishData, setFishData] = useState('')
	const [selectedFishRows, setSelectedFishRows] = useState('')
	const [selectedFish, setSelectedFish] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
	const [commodityData, setCommodityData] = useState('')
	const [selectedRows, setSelectedRows] = useState('')
  const [commodityKey, setCommodityKey] = useState('')
  const [categoryDisabled, setCategoryDisabled] = useState([]) //是否禁用奖品抽奖类别
  const [isDebrisDisabled, setIsDebrisDisabled] = useState([])
  const [debrisProbabilityDisabled, setDebrisProbabilityDisabled] = useState([])
  const [provinceList, setProvinceList] = useState('')
	const [cityList, setCityList] = useState('')
	const [areaList, setAreaList] = useState('')

	// 查询详情
	const getDetail = useCallback(async () => {
		const { data } = await lotteryDetails(parseInt(id))
		console.log(data)
		if (data.code === '00000') {
      const formData = onNeatenData(data.data)
      console.log('formData----',formData)
      setDetailData(formData)
      form.setFieldsValue(formData)
		}
	}, [id, form])

  useEffect(() => {
		if (!!parseInt(id)) getDetail()
	}, [id, getDetail])

  // 图片表单数据处理
  const onNeatenData = (data) => {
    const {
      lottery,
      lotteryPrizes
    } = data
    const {
      title,
      startTime,
      endTime,
      fishId,
      fishName,
      defaultNum,
      goldNum,
      userWinningCeiling,
      prizeExpirationTime,
      prizeExpirationType,
      cardExpirationTime,
      cardExpirationType,
      shareCover,
      shareTitle,
      shareRemark,
      rule,
      scope
    } = lottery
    let converForm = []
    if(shareCover){
      const coverArr = shareCover.split(',')
      coverArr.map((item, i)=>{
        converForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
      setCoverList(converForm)
    }
    if(fishName){
      setSelectedFish({
        name: fishName,
        id: fishId
      })
      setSelectedFishRows([{
        name: fishName,
        id: fishId
      }])
    }
    let activityTime = []
    if(startTime && endTime){
      activityTime = [moment(moment(startTime).format('YYYY-MM-DD'), 'YYYY-MM-DD'), moment(moment(endTime).format('YYYY-MM-DD'), 'YYYY-MM-DD')]
    }
    let newLotteryPrizes = []
    if(lotteryPrizes.length){
      lotteryPrizes.map(item=>{
        const {
          id,
          commodityId,
          commodityName,
          image,
          isDebris,
          category,
          probability,
          debrisProbability,
          joinNumber,
          onceNumber
        } = item
        let imageArr = []
        if(image){
          imageArr.push({
            uid: '-1',
            name: 'xxx.png',
            status: 'done',
            url: image,
            thumbUrl: image
          })
        }
        newLotteryPrizes.push({
          id,
          commodityId,
          commodityName,
          image: {fileList: imageArr},
          isDebris,
          category,
          probability,
          debrisProbability,
          joinNumber,
          onceNumber
        })
        return true
      })
    }
    console.log('newLotteryPrizes',newLotteryPrizes)
    return({
      title,
      activityTime,
      defaultNum,
      goldNum,
      userWinningCeiling,
      prizeExpiration: {
        prizeExpirationTime,
        prizeExpirationType
      },
      cardExpiration: {
        cardExpirationTime,
        cardExpirationType
      },
      shareTitle,
      shareRemark,
      rule,
      shareCover: {fileList: converForm},
      lotteryPrizes: newLotteryPrizes,
      scope
    })
  }

	const onFinish = async () => {
		const formData = await form.validateFields()
		console.log('Received values of form: ', formData)
    const {
      title,
      activityTime,
      scope,
      defaultNum,
      goldNum,
      userWinningCeiling,
      prizeExpiration,
      cardExpiration,
      shareCover,
      shareTitle,
      shareRemark,
      rule,
      lotteryPrizes,
    } = formData
		let coverData = []
		shareCover?.fileList &&
			shareCover.fileList.map((item) => {
				item.url && coverData.push(item.url)
				return true
			})
    lotteryPrizes && lotteryPrizes.map(item => {
      if(item.image && item.image.fileList && item.image.fileList.length){
        if(item.image.fileList[0].response){
          // 新增或者修改图片
          item.image = item.image.fileList[0].response.data
        }else{
          // 原图
          item.image = item.image.fileList[0].url
        }
      }else{
        item.image = ""
      }
      delete item.commodityName
      return item
    })
		let params = {
      type: 0,
      lottery: {
        title,
        scope,
        defaultNum,
        goldNum,
        userWinningCeiling,
        ...prizeExpiration,
        ...cardExpiration,
        shareCover: coverData.join(','),
        shareTitle,
        shareRemark,
        rule,
        fishId: selectedFish.id,
      },
      lotteryPrizes
		}
    if(activityTime){
      let [startTime, endTime] = activityTime
      params.lottery.startTime = moment(startTime).format('YYYY-MM-DD') + ' 00:00:00'
      params.lottery.endTime = moment(endTime).format('YYYY-MM-DD') + ' 23:59:59'
    }
    if(operate === 'edit'){
      params.lottery.id = id*1
    }
    console.log('params---',params)
    const { data } = await addOrUpdateLottery(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleCoverChange = ({ fileList }) => {
		setCoverList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setCoverList([...coverList])
			message.success('上传成功')
		} else {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setCoverList([...coverList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
    console.log(file)
		const { url, status, response } = file
    let filePath = url
		if (status === 'error') {
			return true
		}
    if(!url && response.code !== "00000"){
      return true
    }
    if(!url){
      filePath = response.data
    }
		const { data } = await deleteFile({ filePath })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

  // 选择分类
  const handleCategoryChange = (val, index) => {
    if(val === 3){
      const lotteryPrizes = form.getFieldValue('lotteryPrizes')
      isDebrisDisabled[index] = true
      debrisProbabilityDisabled[index] = true
      lotteryPrizes[index].isDebris = 1
      lotteryPrizes[index].debrisProbability = null
      form.setFieldsValue({lotteryPrizes:[...lotteryPrizes]})
      setIsDebrisDisabled([...isDebrisDisabled])
      setDebrisProbabilityDisabled([...debrisProbabilityDisabled])
    }else{
      isDebrisDisabled[index] = false
      debrisProbabilityDisabled[index] = false
      setIsDebrisDisabled([...isDebrisDisabled])
      setDebrisProbabilityDisabled([...debrisProbabilityDisabled])
    }
  }

  const handleIsDebrisChange = (val, index) => {
    if(val === 1){
      const lotteryPrizes = form.getFieldValue('lotteryPrizes')
      debrisProbabilityDisabled[index] = true
      lotteryPrizes[index].debrisProbability = null
      form.setFieldsValue({lotteryPrizes:[...lotteryPrizes]})
      setDebrisProbabilityDisabled([...debrisProbabilityDisabled])
    }else{
      debrisProbabilityDisabled[index] = false
      setDebrisProbabilityDisabled([...debrisProbabilityDisabled])
    }
  }


  // 选择商品
	const showModal = (key) => {
    const lotteryPrizes = form.getFieldValue('lotteryPrizes')
    if(lotteryPrizes[key] && lotteryPrizes[key].commodityId)setSelectedRows([{id: lotteryPrizes[key].commodityId, name: lotteryPrizes[key].commodityName}])
    commodityForm.resetFields()
    setCommodityKey(key)
		setIsModalVisible(true)
		getCommodityList()
	}

  // 商品列表
  const getCommodityList = (param) => {
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    commodityList(params).then(({ data }) => {
			if (data.code === '00000') {
				setCommodityData(data.data)
			}
		})
  }

  // 查询商品
  const onCommodityFinish = (values) => {
    getCommodityList(values)
  }

  /**
	 * 分页
	 * @param page
	 */
	const handleCommodityChangePage = (page) => {
		const data = { current: page }
		getCommodityList(data)
	}

	const handleOk = () => {
    if(selectedRows.length){
      const lotteryPrizes = form.getFieldValue('lotteryPrizes')
      lotteryPrizes[commodityKey].commodityId = selectedRows[0].id
      lotteryPrizes[commodityKey].commodityName = selectedRows[0].name
      lotteryPrizes[commodityKey].category = selectedRows[0].type
      form.setFieldsValue({lotteryPrizes:[...lotteryPrizes]})
      categoryDisabled[commodityKey] = true
      setCategoryDisabled([...categoryDisabled])
    }
		setSelectedRows([])
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setSelectedRows([])
		setIsModalVisible(false)
	}

  // 选择商品
	const rowSelection = {
		selectedRowKeys: selectedRows[0]?.id ? [selectedRows[0].id] : '',
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows)
		},
	}

	// 商品
	const columns = [
		{
			title: '商品名称',
			dataIndex: 'name',
			key: 'name',
		},
	]

	// 选择钓场
	const showFishModal = () => {
    fishForm.resetFields()
		setIsFishModalVisible(true)
		getFishList({provinceId: 1922, cityId: 1946})
	}

  useEffect(() => {
		if(districtData){
			setProvinceList(districtData)
		}
	}, [districtData])

	// 更换省份
	const handleProvinceChange = (id, item) => {
		if(id){
			const { key } = item
			setCityList(provinceList[key].cityList)
			form.setFieldsValue({cityId: '', areaId: ''})
		}
	}

	const handleCityChange = (id, item) => {
		if(id){
			const { key } = item
			setAreaList(cityList[key].areaList)
			form.setFieldsValue({areaId: ''})
		}
	}

  // 钓场列表
  const getFishList = (param) => {
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    fishList(params).then(({ data }) => {
			if (data.code === '00000') {
				setFishData(data.data)
			}
		})
  }

  useEffect(() => {
		if(provinceList && provinceList.length && isFishModalVisible){
			const checkPronce = provinceList.filter(info => info.id === 1922)
			setCityList(checkPronce[0].cityList)
			fishForm.setFieldsValue({provinceId: 1922, cityId: 1946})
		}
	}, [fishForm, provinceList, isFishModalVisible])

  // 钓场查询
  const onFishFinish = (values) => {
    getFishList(values)
  }

  /**
	 * 分页
	 * @param page
	 */
	const handleFishChangePage = (page) => {
		const data = { current: page }
		getFishList(data)
	}

	const handleFishOk = () => {
		setSelectedFish(selectedFishRows[0])
		setIsFishModalVisible(false)
	}

	const handleFishCancel = () => {
		setSelectedFishRows([selectedFish])
		setIsFishModalVisible(false)
	}

	// 选择钓场
	const rowFishSelection = {
		selectedRowKeys: selectedFishRows[0]?.id
			? [selectedFishRows[0].id]
			: '',
		onChange: (selectedRowKeys, selectedFishRows) => {
			setSelectedFishRows(selectedFishRows)
		},
	}

	// 钓场
	const columnsFish = [
		{
			title: '钓场名称',
			dataIndex: 'name',
			key: 'name',
		},
    {
			title: '省份',
			dataIndex: 'provinceName',
			key: 'provinceName',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '市',
			dataIndex: 'cityName',
			key: 'cityName',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '电话',
			dataIndex: 'phone',
			key: 'phone',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '收费状态',
			dataIndex: 'payType',
			key: 'payType',
			render: (d) => <span>{d === 0 ? '计时' : d === 1 ? '记重' : d === 2 ? '混合' : d === 3 ? '套餐' : '免费'}</span>,
		},
		{
			title: '类别',
			dataIndex: 'type',
			key: 'type',
			render: (d) => <span>{d === 0 ? '钓场' : '渔具店'}</span>,
		}
	]

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => window.history.back()}
					title={
						operate === 'add'
							? '新增'
							: operate === 'edit'
							? '编辑'
							: '查看'
					}
					extra={[
						operate !== 'look' && (
							<Button type="primary" onClick={onFinish}>
								保存
							</Button>
						),
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form
					labelWrap
					{...layout}
					form={form}
				>
          <Collapse defaultActiveKey={['1','2']}>
            <Panel header="基础信息" key="1">
              <Form.Item
                name="title"
                label="挖宝名称"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={operate === 'look' ? true : false} />
              </Form.Item>
              <Form.Item name="activityTime" label="挖宝时间">
                <RangePicker
                  disabled={operate === 'look' ? true : false}
                />
              </Form.Item>
              <Form.Item name="fish" label="挖宝区域">
                <div>
                  <Button
                    type="primary"
                    onClick={showFishModal}
                    disabled={operate === 'look' ? true : false}
                  >
                    选择挖宝区域
                  </Button>
                  <span className="ml10">
                    {selectedFish.name || '无'}
                  </span>
                </div>
              </Form.Item>
              {
                selectedFish.name ? <Form.Item name="scope" label="钓场最大范围(公里)">
                  <InputNumber
                    disabled={operate === 'look' ? true : false}
                  />
                </Form.Item> : ''
              }
              <Form.Item name="defaultNum" label="每天抽奖次数">
                <InputNumber
                  disabled={operate === 'look' ? true : false}
                />
              </Form.Item>
              <Form.Item name="goldNum" label="一次金币数量">
                <InputNumber
                  disabled={operate === 'look' ? true : false}
                />
              </Form.Item>
              <Form.Item name="userWinningCeiling" label="用户中奖上限">
                <InputNumber
                  disabled={operate === 'look' ? true : false}
                />
              </Form.Item>
              <Form.Item label="实物奖品领取时效">
                <Input.Group compact>
                  <Form.Item
                    name={['prizeExpiration', 'prizeExpirationTime']}
                    noStyle
                  >
                    <InputNumber disabled={operate === 'look' ? true : false}/>
                  </Form.Item>
                  <Form.Item
                    name={['prizeExpiration', 'prizeExpirationType']}
                    noStyle
                    initialValue={0}
                  >
                    <Select disabled={operate === 'look' ? true : false}>
                      <Select.Option value={0}>小时</Select.Option>
                      <Select.Option value={1}>天</Select.Option>
                      <Select.Option value={2}>月</Select.Option>
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label="虚拟卡卷领取时效">
                <Input.Group compact>
                  <Form.Item
                    name={['cardExpiration', 'cardExpirationTime']}
                    noStyle
                  >
                    <InputNumber disabled={operate === 'look' ? true : false}/>
                  </Form.Item>
                  <Form.Item
                    name={['cardExpiration', 'cardExpirationType']}
                    noStyle
                    initialValue={0}
                  >
                    <Select disabled={operate === 'look' ? true : false}>
                      <Select.Option value={0}>小时</Select.Option>
                      <Select.Option value={1}>天</Select.Option>
                      <Select.Option value={2}>月</Select.Option>
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item name="shareCover" label="分享封面">
                <Upload
                  disabled={operate === 'look' ? true : false}
                  customRequest={upLoadFile}
                  listType="picture-card"
                  fileList={coverList}
                  onPreview={handlePreview}
                  onChange={handleCoverChange}
                  onRemove={removeFile}
                >
                  {coverList.length >= maxCover ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item
                name="shareTitle"
                label="分享标题"
              >
                <Input disabled={operate === 'look' ? true : false} />
              </Form.Item>
              <Form.Item
                name="shareRemark"
                label="分享备注"
              >
                <Input disabled={operate === 'look' ? true : false} />
              </Form.Item>
              <Form.Item name="rule" label="挖宝规则">
                <TextArea
                  rows={4}
                  disabled={operate === 'look' ? true : false}
                />
              </Form.Item>
            </Panel>
            <Panel header="奖品信息" key="2">
              <Form.List name="lotteryPrizes">
                {(fields, { add, remove }) => (
                  <div className="table_form_list">
                    <Space>
                      <div style={{width: '272px'}}>商品</div>
                      <div style={{width: '98px'}}>抽奖类别</div>
                      <div style={{width: '84px'}}>是否使用碎片</div>
                      <div style={{width: '148px'}}>奖品图片</div>
                      <div style={{width: '90px'}}>概率</div>
                      <div style={{width: '90px'}}>碎片概率</div>
                      <div style={{width: '90px'}}>参与挖宝数量</div>
                      <div style={{width: '90px'}}>单次抽奖数量</div>
                    </Space>
                    <Divider />
                    {fields.map(({ key, name, ...restField }) => (
                      <div>
                        <Space key={key} style={{ display: 'flex'}} align="baseline">
                            <div className="select_commodity" style={{width: '272px'}}>
                              {
                                operate !== 'look' ? <Button
                                  type="primary"
                                  onClick={()=>showModal(name)}
                                  disabled={operate === 'look' ? true : false}
                                >
                                  选择商品
                                </Button> : ''
                              }
                              <Form.Item
                                {...restField}
                                name={[name, 'commodityId']}
                                hidden
                              >
                                <InputNumber disabled={true} style={{diaplay: 'none'}}/>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'commodityName']}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                            </div>
                            <Form.Item
                              {...restField}
                              name={[name, 'category']}
                              initialValue={0}
                              style={{width: '98px'}}
                            >
                              <Select
                                disabled={operate === 'look' || categoryDisabled[name] ? true : false}
                                onChange={(val)=>handleCategoryChange(val, name)}
                              >
                                <Select.Option value={0}>虚拟卡卷</Select.Option>
                                <Select.Option value={1}>实物奖品</Select.Option>
                                {/* <Select.Option value={2}>金币</Select.Option> */}
                                <Select.Option value={3}>未中奖</Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'isDebris']}
                              initialValue={0}
                              style={{width: '84px'}}
                            >
                              <Select
                                disabled={operate === 'look' || isDebrisDisabled[name] ? true : false} style={{width: '84px'}}
                                onChange={(val)=>handleIsDebrisChange(val, name)}
                              >
                                <Select.Option value={0}>是</Select.Option>
                                <Select.Option value={1}>否</Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'image']}
                              style={{width: '148px'}}
                            >
                              <Upload
                                className="upload_table"
                                action="/naerdiao/file/uploadfile?check=naerdiao"
                                listType="picture"
                                defaultFileList={detailData?.lotteryPrizes?.[name]?.image ? [...detailData.lotteryPrizes[name].image.fileList] : []}
                                maxCount={1}
                                onPreview={handlePreview}
                                onRemove={removeFile}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'probability']}
                              initialValue={0}
                            >
                              <InputNumber
                                max={100}
                                formatter={value => `${value}%`}
                                precision={3}
                                disabled={operate === 'look' ? true : false}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'debrisProbability']}
                            >
                              <InputNumber
                                max={100}
                                formatter={value => `${value}%`}
                                precision={3}
                                disabled={operate === 'look' || debrisProbabilityDisabled[name] ? true : false}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'joinNumber']}
                            >
                              <InputNumber
                                disabled={operate === 'look' ? true : false}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'onceNumber']}
                              initialValue={1}
                            >
                              <InputNumber
                                min={1}
                                disabled={operate === 'look' ? true : false}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                          <Divider />
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        增加奖品
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </Panel>
          </Collapse>
				</Form>
			</div>
			<Modal
				title="钓场区域"
				centered
				visible={isFishModalVisible}
				onOk={handleFishOk}
				onCancel={handleFishCancel}
        width={1000}
			>
				<Form
					form={fishForm}
					className="public_search_box"
					onFinish={onFishFinish}
				>
					<Row gutter={24}>
						<Col span={6}>
							<Form.Item name={`name`}>
								<Input placeholder="钓场名称" />
							</Form.Item>
						</Col>
            <Col span={6}>
							<Form.Item name="serviceType" label="类别" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									<Select.Option value={0}>钓场</Select.Option>
									<Select.Option value={1}>渔具店</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="type" label="类型" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									<Select.Option value={0}>黑坑</Select.Option>
									<Select.Option value={1}>野钓</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="provinceId" label="省" initialValue="">
								<Select onChange={handleProvinceChange}>
									<Select.Option value="">全国</Select.Option>
									{
										provinceList && provinceList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="cityId" label="市" initialValue="">
								<Select onChange={handleCityChange}>
									<Select.Option value="">全部</Select.Option>
									{
										cityList && cityList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="areaId" label="区" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									{
										areaList && areaList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Button type="primary" htmlType="submit">
								查询
							</Button>
							<Button
								style={{ margin: '0 8px' }}
								onClick={() => {
									fishForm.resetFields()
								}}
							>
								清空
							</Button>
						</Col>
					</Row>
				</Form>
				<Table
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						type: 'radio',
						...rowFishSelection,
					}}
					dataSource={fishData.list}
					columns={columnsFish}
          scroll={{ x: 'max-content'}}
          pagination={{
						current: fishData.current*1,
						total: fishData.total*1,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleFishChangePage,
					}}
				/>
			</Modal>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
      <Modal
				title="商品"
				centered
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Form
					form={commodityForm}
					className="public_search_box"
					onFinish={onCommodityFinish}
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item name={`name`}>
								<Input placeholder="商品名称" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Button type="primary" htmlType="submit">
								查询
							</Button>
							<Button
								style={{ margin: '0 8px' }}
								onClick={() => {
									commodityForm.resetFields()
								}}
							>
								清空
							</Button>
						</Col>
					</Row>
				</Form>
				<Table
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						type: 'radio',
						...rowSelection,
					}}
					dataSource={commodityData.list}
					columns={columns}
          scroll={{ x: 'max-content' }}
          pagination={{
						current: commodityData.current*1,
						total: commodityData.total*1,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleCommodityChangePage,
					}}
				/>
			</Modal>
		</div>
	)
}
