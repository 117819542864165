import React from 'react';
import { Route, Routes } from "react-router-dom";
import ActivityManage from "../ActivityManage"
import ActivityManageDetails from "../ActivityManage/details"
import GoodsManage from "../GoodsManage"
import GoodsManageDetails from "../GoodsManage/details"
import GoodsManageDebris from "../GoodsManage/debris"
import OrderManage from "../OrderManage"
import SkillClassify from "../SkillManage/Classify"
import SkillClassifyDetails from "../SkillManage/Classify/details"
import SkillContents from "../SkillManage/Contents"
import SkillContentsDetails from "../SkillManage/Contents/details"
import OrbManage from "../OrbManage"
import OrbManageDetails from "../OrbManage/details"
import FishManage from "../FishManage"
import FishManageDetails from "../FishManage/details"
import DynamicManage from "../DynamicManage"
import Feedback from "../User/Feedback"
import LotteryManage from "../LotteryManage"
import LotteryManageDetails from "../LotteryManage/details"

const myRouter = () => (
  <Routes>
    <Route exact path="/home/ActivityManage" element={<ActivityManage/>} />
    <Route exact path="/home/ActivityManage/details/:operate/:id" element={<ActivityManageDetails/>} />
    <Route exact path="/home/GoodsManage" element={<GoodsManage/>} />
    <Route exact path="/home/GoodsManage/details/:operate/:id" element={<GoodsManageDetails/>} />
    <Route exact path="/home/GoodsManage/debris/:operate/:id" element={<GoodsManageDebris/>} />
    <Route exact path="/home/OrderManage" element={<OrderManage/>} />
    <Route exact path="/home/Skill/Classify" element={<SkillClassify/>} />
    <Route exact path="/home/Skill/Classify/details/:operate/:id" element={<SkillClassifyDetails/>} />
    <Route exact path="/home/Skill/Contents" element={<SkillContents/>} />
    <Route exact path="/home/Skill/Contents/details/:operate/:id" element={<SkillContentsDetails/>} />
    <Route exact path="/home/OrbManage" element={<OrbManage/>} />
    <Route exact path="/home/OrbManage/details/:operate/:id" element={<OrbManageDetails/>} />
    <Route exact path="/home/FishManage" element={<FishManage/>} />
    <Route exact path="/home/FishManage/details/:operate/:id" element={<FishManageDetails/>} />
    <Route exact path="/home/DynamicManage" element={<DynamicManage/>} />
    <Route exact path="/home/User/Feedback" element={<Feedback/>} />
    <Route exact path="/home/LotteryManage" element={<LotteryManage/>} />
    <Route exact path="/home/LotteryManage/details/:operate/:id" element={<LotteryManageDetails/>} />
  </Routes>
)

export default myRouter
