import {
  myAxios as axios
} from '../index'
// import Qs from 'qs'

/**
 * 订单列表
 * @param params
 * @returns {*}
 */
export const orderList = (params) => {
  return axios({
    url: `/naerdiao/order/orderList`,
    data: params
  })
}

/**
 * 修改订单
 * @param params
 * @returns {*}
 */
export const updateOrder = (params) => {
  return axios({
    url: `/naerdiao/order/updateOrder`,
    data: params
  })
}



