import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	Upload,
	message,
	Modal,
	Affix,
	Cascader,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	uploadfile,
	deleteFile,
	skillMainView,
	skillMainAdd,
	skillMainUpdate,
	skillGetClassList,
} from '@/https/SkillManage'
import E from 'wangeditor'

export default function Page() {
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
  const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxCover = 10
  const editor = useRef(null)

	const [detailData, setDetailData] = useState('')
	const [classOptions, setClassOptions] = useState([])
  const [classAll, setClassAll] = useState([])
  const [cascaderValue, setCascaderValue] = useState([])
	const [coverList, setCoverList] = useState([])
  const [bannerList, setBannerList] = useState([])
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')

	// 查询详情
	const getDetail = useCallback(async () => {
		const { data } = await skillMainView(id)
		if (data.code === '00000') {
			const formData = onNeatenData(data.data)
			setDetailData(formData)
			form.setFieldsValue(formData)
		}
	}, [id, form])

	useEffect(() => {
		if (id) getDetail()
	}, [id, getDetail])

  useEffect(() => {
		if (detailData && classAll.length) {
      const filtrationClassId = (classIds) => {
        for(let i = 0; i < classAll.length; i++){
          let item = classAll[i]
          if(item.id === classIds[0]){
            if(item.pid !== 0){
              classIds.unshift(item.pid)
              filtrationClassId(classIds)
            }else{
              setClassAll([])
              setCascaderValue([...classIds])
							form.setFieldsValue({parent: classIds})
              break;
            }
          }
        }
      }
      filtrationClassId([detailData.classId])
    }
	}, [classAll, detailData, form])

	// 分类数组拼接
	const classJoint = useCallback((initData, pid, level) => {
		let reachedLevel = initData.filter(
			(info) => info.pid === pid && info.level === level
		)
		let nextLevel = initData.filter((info) => info.level > level)
		if (nextLevel.length > 0) {
			reachedLevel.map((item) => {
				let children = classJoint(nextLevel, item.id, level + 1)
				if (children.length) {
					item.children = children
				}
				return true
			})
		}
		return reachedLevel
	}, [])

	// 查询分类
	useEffect(() => {
    const getClassList = async () => {
      const { data } = await skillGetClassList({})
      if (data.code === '00000' && data.data.length) {
        setClassAll(data.data)
        const classArr = classJoint(data.data, 0, 1)
        setClassOptions(classArr)
      }
    }
		getClassList()
	}, [classJoint])

	const onChange = (value) => {
		setCascaderValue(value)
	}

	// 图片表单数据处理
	const onNeatenData = (data) => {
		let converForm = []
		if (data.coverPictureUrl) {
			const coverArr = data.coverPictureUrl.split(',')
      const coverPictureInfo= JSON.parse(data.coverPictureInfo)
			coverArr.map((item, i) => {
				converForm.push({
					uid: i,
					url: item,
					status: 'done',
          width: coverPictureInfo[i].width,
          height: coverPictureInfo[i].height
				})
				return true
			})
			setCoverList(converForm)
		}
    let pictureForm = []
    if(data.textPictureUrl){
      const pictureArr = data.coverPictureUrl.split(',')
      const textPictureInfo = JSON.parse(data.textPictureInfo)
			pictureArr.map((item, i) => {
				pictureForm.push({
					uid: i,
					url: item,
					status: 'done',
          width: textPictureInfo[i].width,
          height: textPictureInfo[i].height
				})
				return true
			})
			setBannerList(pictureForm)
    }
    const {classId, title, keyword, textContent} = data
		return {
			classId,
      title,
      keyword,
      textContent,
			coverPictureUrl: { fileList: converForm },
      textPictureUrl: { fileList: pictureForm }
		}
	}

  // 富文本
	useEffect(() => {
		editor.current = new E('#detail_html')
		const editorDetail = editor.current
		editorDetail.config.zIndex = 100
		// 上传图片
		editorDetail.config.uploadImgMaxLength = 1 // 一次最多上传 5 个图片
		editorDetail.config.customUploadImg = function (resultFiles, insertImgFn) {
			// resultFiles 是 input 中选中的文件列表
			// insertImgFn 是获取图片 url 后，插入到编辑器的方法
			let formData = new FormData()
			formData.append('file', resultFiles[0])
			uploadfile(formData).then(({data}) => {
				if (data.code === '00000') {
					insertImgFn(data.data)
					message.success('上传成功')
				} else {
					message.error(data.message)
				}
			}).catch(res => {
				console.log('res :>> ', res);
				message.error('上传失败，代码或者接口报错')
			})
			// 上传图片，返回结果，将图片插入到编辑器中
			// insertImgFn(imgUrl)
		}
		editorDetail.create()
    editor.current.txt.html(detailData.textContent) 
    // 禁用
    if(operate === 'look') editorDetail.disable();
		return () => {
			// 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
			editorDetail.destroy()
		}
	}, [detailData, operate])

	const onFinish = async () => {
		const formData = await form.validateFields()
		console.log('Received values of form: ', formData)
    const content = editor.current.txt.html()
    const classId = cascaderValue[cascaderValue.length - 1]
		let coverData = [], coverPictureNum = 0, coverPictureInfo = [], textPictureData = [], textPictureInfo= []
		formData?.coverPictureUrl?.fileList &&
			formData.coverPictureUrl.fileList.map((item) => {
				coverData.push(item.url)
        coverPictureNum++
        coverPictureInfo.push({
          width: item.width,
          height: item.height
        })
				return true
			})
    formData?.textPictureUrl?.fileList &&
			formData.textPictureUrl.fileList.map((item) => {
				textPictureData.push(item.url)
        textPictureInfo.push({
          width: item.width,
          height: item.height
        })
				return true
			})
		const params = {
			...formData,
      classId,
      textContent: content,
			coverPictureUrl: coverData.join(','),
      coverPictureNum,
      coverPictureInfo: JSON.stringify(coverPictureInfo),
      textPictureUrl: textPictureData.join(','),
      textPictureInfo: JSON.stringify(textPictureInfo)
		}
		let postFun
		if (operate === 'add') {
			postFun = skillMainAdd
		} else {
			postFun = skillMainUpdate
			params.id = id * 1
		}
		const { data } = await postFun(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleCoverChange = ({ fileList }) => {
		setCoverList(fileList)
	}

  const handleBannerChange = ({ fileList }) => {
		setBannerList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
      const image=new Image();  
      image.onload=()=>{
        coverList.filter((info) => {
          if (info.uid === uid) {
            info.url = data.data
            info.status = 'done'
            info.width = image.width
            info.height = image.height
          }
          return true
        })
        setCoverList([...coverList])
        message.success('上传成功')
      }; 
      image.src=data.data; 
		} else {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setCoverList([...coverList])
			message.error(data.message)
		}
	}

  const upLoadBannerFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
      const image=new Image();  
      image.onload=()=>{
        bannerList.filter((info) => {
          if (info.uid === uid) {
            info.url = data.data
            info.status = 'done'
            info.width = image.width
            info.height = image.height
          }
          return true
        })
        setBannerList([...bannerList])
        message.success('上传成功')
      }; 
      image.src=data.data; 
		} else {
			bannerList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setBannerList([...bannerList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
		const { url, status } = file
		if (status === 'error') {
			return true
		}
		const { data } = await deleteFile({ filePath: url })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => window.history.back()}
					title={
						operate === 'add'
							? '新增'
							: operate === 'edit'
							? '编辑'
							: '查看'
					}
					extra={[
						operate !== 'look' && (
							<Button type="primary" onClick={onFinish}>
								保存
							</Button>
						),
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form labelWrap {...layout} form={form} initialValues={{}}>
          <Form.Item
						name="parent"
						label="分类"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Cascader
							disabled={operate === 'look' ? true : false}
              fieldNames={{ label: 'name', value: 'id', children: 'children' }}
							value={cascaderValue}
							options={classOptions}
							onChange={onChange}
							changeOnSelect
							expandTrigger="hover"
              placeholder="选择分类"
						/>
					</Form.Item>
					<Form.Item
						name="title"
						label="标题"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input disabled={operate === 'look' ? true : false} autocomplete="off"/>
					</Form.Item>
          <Form.Item
						name="keyword"
						label="关键字"
					>
						<Input disabled={operate === 'look' ? true : false} autocomplete="off"/>
					</Form.Item>
					<Form.Item name="coverPictureUrl" label="封面">
						<Upload
							disabled={operate === 'look' ? true : false}
							customRequest={upLoadFile}
							listType="picture-card"
							fileList={coverList}
							onPreview={handlePreview}
							onChange={handleCoverChange}
							onRemove={removeFile}
						>
							{coverList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
          <Form.Item name="textPictureUrl" label="内容图片">
						<Upload
              disabled={operate === 'look' ? true : false}
							customRequest={upLoadBannerFile}
							listType="picture-card"
							fileList={bannerList}
							onPreview={handlePreview}
							onChange={handleBannerChange}
							onRemove={removeFile}
						>
							{bannerList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
          <Form.Item name="textContent" label="正文内容">
						<div id="detail_html"></div>
					</Form.Item>
				</Form>
			</div>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	)
}
