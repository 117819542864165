import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt'

// 不用加密的接口
const noEncryption = [
  '/file/uploadfile',
  '/file/videoUpload',
  '/file/deleteFile'
]

const randomString = () => {
  const len = 10
  const allString = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = allString.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += allString.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd
}

const sortAscii = (obj) => {
  let arr = [];
  let num = 0;
  for (let i in obj) {
    arr[num] = i;
    num++;
  }
  let sortArr = arr.sort();
  //let sortObj = {};    //完成排序值
  let str = ''; //自定义排序字符串
  for (let i in sortArr) {
    str += sortArr[i] + '=' + transitionType(obj[sortArr[i]]) + '&';
  }
  //去除两侧字符串
  let char = '&'
  str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');

  return str;
}

// obj对象排序
const sortObjAscii = (obj) => {
  let arr = [];
  let num = 0;
  for (let i in obj) {
    arr[num] = i;
    num++;
  }
  let sortArr = arr.sort();
  //let sortObj = {};    //完成排序值
  let str = {}; //自定义排序字符串
  for (let i in sortArr) {
    str[sortArr[i]] = transitionType(obj[sortArr[i]])
  }
  return str;
}

// 对象数组转字符串
const transitionType = (data) => {
  if(Object.prototype.toString.call(data) === "[object Object]"){
    return JSON.stringify(data)
  }else if(Object.prototype.toString.call(data) === "[object Array]"){
    return JSON.stringify(data)
  }else{
    return data
  }
}

// 去除空值，null，undefined
const trimData = (param) => {
  let params = {}
  for (var key in param) {
    if(Object.prototype.toString.call(param[key]) === "[object Object]"){
      params[key] = trimData(sortObjAscii(param[key]))
    }else if(Object.prototype.toString.call(param[key]) === "[object Array]"){
      params[key] = trimArray(param[key])
    }else if(param[key] !== null && param[key] !== undefined){
      params[key] = param[key]
    }
  }
  return params
}

// 数组处理
const trimArray = (data) => {
  let newArr = []
  data.map(info => {
    if(Object.prototype.toString.call(info) === "[object Object]"){
      newArr.push(trimData(sortObjAscii(info)))
    }else if(Object.prototype.toString.call(info) === "[object Array]"){
      newArr.push(trimArray(info))
    }else if(info !== null && info !== undefined){
      newArr.push(info)
    }
    return true
  })
  return newArr
}

const encryptMdRsa = (config) => {
  let param = config.method === 'get' ? config.params : config.data
  // 判断参数是否需要加密
  const noEncryptUrl = noEncryption.filter(info => config.url.includes(info)).length
  if(noEncryptUrl){
    param = ""
  }
  const timestamp = new Date().getTime()
  const uuid = randomString()
  let option = {}
  if (param) {
    let params = trimData(param)
    option = {
      ...params,
      timestamp,
      uuid
    }
  } else {
    option = {
      timestamp,
      uuid
    }
  }
  option = sortAscii(option)
  const encryptStr = option + '&appKey=b26f4832cc4230c9c81a713cdf2951fb'
  const encryptMd5 = md5(encryptStr);

  let encrypt = new JSEncrypt();
  const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQ4Pmql8RCMwdd1kqojtlIs4oXgVSI9AruiId/5xEVNKflBpqcynNAwNZ9lQIKqpzwo22mpiESQDEt7khlqjio2TdNdnd3Mt4BO1Cnv2YsA5m7LRzkRYf83FN1YL8M/H67FkeuIvIxKaYZNXO7POw88MNDb8/IsClqgRch2s3q9wIDAQAB'
  encrypt.setPublicKey(publicKey);
  const sign = encrypt.encrypt(encryptMd5);

  return {
    sign,
    uuid,
    timestamp
  }
}

export default encryptMdRsa