import {
  myAxios as axios
} from '../index'
import Qs from 'qs'

/**
 * 商品列表
 * @param params
 * @returns {*}
 */
export const commodityList = (params) => {
  return axios({
    url: `/naerdiao/commodity/list`,
    data: params
  })
}

/**
 * 删除商品
 * @param params
 * @returns {*}
 */
export const delCommodity = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/commodity/${id}`
  })
}

/**
 * 供应商列表
 * @param params
 * @returns {*}
 */
export const fishList = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/getPage`,
    data: params
  })
}

/**
 * 上传文件
 * @param params
 * @returns {*}
 */
export const uploadfile = (params) => {
  return axios({
    url: `/naerdiao/file/uploadfile?check=naerdiao`,
    data: params,
    transformRequest: [
      function (data) {
        return data
      },
    ]
  })
}

/**
 * 删除图片
 * @param params
 * @returns {*}
 */
export const deleteFile = (params) => {
  return axios({
    url: `/naerdiao/file/deleteFile`,
    data: params,
    transformRequest: [
      function (data) {
        data = Qs.stringify(data)
        return data
      },
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

/**
 * 新增商品
 * @param params
 * @returns {*}
 */
export const addCommodity = (params) => {
  return axios({
    url: `/naerdiao/commodity/add`,
    data: params
  })
}

/**
 * 修改商品
 * @param params
 * @returns {*}
 */
export const updateCommodity = (params) => {
  return axios({
    url: `/naerdiao/commodity/update`,
    data: params
  })
}

/**
 * 商品碎片详情
 * @param params
 * @returns {*}
 */
export const getByCommodityId = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/commodityDebris/getByCommodityId`,
    params
  })
}

/**
 * 新增商品碎片
 * @param params
 * @returns {*}
 */
export const addCommodityDebris = (params) => {
  return axios({
    url: `/naerdiao/commodityDebris/addCommodityDebris`,
    data: params
  })
}

/**
 * 修改商品碎片
 * @param params
 * @returns {*}
 */
export const updateCommodityDebris = (params) => {
  return axios({
    url: `/naerdiao/commodityDebris/updateCommodityDebris`,
    data: params
  })
}


