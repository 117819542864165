import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Form, Row, Col, Button, Table, Select, message, Modal, Spin, Image, Radio, Input } from 'antd'
import debounce from 'lodash/debounce';
import { fishList, getDynamicPage, getVideoInfo, updateFishStatus } from '@/https/DynamicManage'
import moment from 'moment'

const { TextArea } = Input;

export default function Page() {
  const [form] = Form.useForm()
	const [auditform] = Form.useForm()
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const [loading, setLoading] = useState(false)
  const [dynamicData, setDynamicData] = useState('')
	const [imgVisible, setImgVisible] = useState(false);
	const [imgList, setImgList] = useState([]);
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [dynamicItem, setDynamicItem] = useState('')
	const [dynamicVisible, setDynamicVisible] = useState(false)

  // 下拉框钓场搜索
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      console.log('value :>> ', value);
      fishList({current: 1, pageSize: 20, name: value}).then(({data}) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        const newOptions = data.data.list
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
		// getAreaList()
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
			statusReviewAllFlag:0
		}
		let params = { ...option, ...param }
		const { data } = await getDynamicPage(params)
		setLoading(false)
		if (data.code === '00000') {
			setDynamicData(data.data)
		}
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

	// 查看图片或者视频
	const handleLookDynamic = async (item) => {
		if(item.type === 0){
			// 图片
			setImgList(item.url.split(','))
			setImgVisible(true)
		}else{
			const { data } = await getVideoInfo({ videoId: item.videoId })
			setPreviewImage(data.data.PlayInfoList.PlayInfo[0].PlayURL)
			setPreviewVisible(true)
		}
	}

	// 展示动态审核
	const handleShowDynamic = async (item) => {
		if(item.type !== 0){
			const { data } = await getVideoInfo({ videoId: item.videoId })
			setPreviewImage(data.data.PlayInfoList.PlayInfo[0].PlayURL)
		}
		setDynamicItem({...item})
		setDynamicVisible(true)
	}

	// 展示图片或者视频
	const showModalFile = (item) => {
		if(item.type === 0){
			const urlArr = item.url.split(',')
			return (<Image.PreviewGroup>
				{
					urlArr.length && urlArr.map(item => {
						return (
							<Image width={100} src={item}/>
						)
					})
				}
			</Image.PreviewGroup>)
		}else{
			return (
				<video width="200" height="300" controls>
				{/* <source src="movie.ogg" type="video/ogg"/> */}
				<source src={previewImage} type="video/mp4" />
				{/* <source src="movie.webm" type="video/webm"/> */}
				<object data={previewImage} width="472" height="300">
					{/* <embed width="472" height="600" src="movie.swf"/> */}
				</object>
			</video>)
		}
	}


	// 审核提交
	const handleOk = async () => {
		const formData = await auditform.validateFields()
		const params = {
			...formData,
			documentId: dynamicItem.id
		}
		const { data } = await updateFishStatus(params)
		if(data.code === "00000"){
			message.success("审核成功")
			getList()
			setDynamicVisible(false)
			auditform.resetFields()
		}else{
			message.error('提交审核失败')
		}
	}

  const columns = [{
		title: '标题',
		dataIndex: 'title',
		key: 'title',
	}, {
		title: '文件类型',
		dataIndex: 'type',
		key: 'type',
		render: (d) => <span>{d === 0 ? '图片' : d === 1 ? '普通视频' : '小视频'}</span>,
	}, {
		title: '审核状态',
		dataIndex: 'statusReview',
		key: 'statusReview',
		render: (d) => <span>{d === 0 ? '未审核' : d === 1 ? '审核通过' : '审核不通过'}</span>,
	}, {
		title: '创建时间',
		dataIndex: 'createTime',
		key: 'createTime',
		render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
	}, {
		title: '备注',
		dataIndex: 'remark',
		key: 'remark',
		render: (d) => <span>{d ? d : '---'}</span>,
	}, {
		title: '操作',
		key: 'operation',
		fixed: 'right',
		width: 160,
		render: (text, record, index) => (
			<span>
        <Button
					className='mr10'
          size="small"
          onClick={() => handleLookDynamic(record)}
        >
          查看
        </Button>
        {
          record.statusReview === 0 && <Button
            type="primary"
            size="small"
            onClick={() => handleShowDynamic(record)}
          >
            审核
          </Button>
        }
			</span>
		),
	}]

  return (
    <div className="public_content_box">
      <Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={6}>
						<Form.Item name="sourceId" label="钓场" initialValue="">
							<Select
                showSearch
                filterOption={false}
                placeholder="搜索钓场名称"
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={(options || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              >
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
      <div className="public_table">
				<Table
					loading={loading}
					dataSource={dynamicData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: dynamicData.current,
						total: dynamicData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
			<div style={{ display: 'none' }}>
				<Image.PreviewGroup preview={{ visible:imgVisible, onVisibleChange: vis => setImgVisible(vis) }}>
					{
						imgList.length && imgList.map(item => {
							return (
								<Image src={item} />
							)
						})
					}
				</Image.PreviewGroup>
			</div>
			<Modal
				visible={previewVisible}
				title={"查看视频"}
				footer={null}
				centered
				onCancel={() => setPreviewVisible(false)}
			>
				<video width="472" height="600" controls>
					{/* <source src="movie.ogg" type="video/ogg"/> */}
					<source src={previewImage} type="video/mp4" />
					{/* <source src="movie.webm" type="video/webm"/> */}
					<object data={previewImage} width="472" height="300">
						{/* <embed width="472" height="600" src="movie.swf"/> */}
					</object>
				</video>
			</Modal>
			<Modal
				visible={dynamicVisible}
				title={"动态审核"}
				centered
				width={460}
				onOk={handleOk}
				onCancel={() => {
					setDynamicVisible(false)
					auditform.resetFields()
				}}
			>
				<Form form={auditform}>
					<Form.Item label="标题" >
						<div>{dynamicItem.title}</div>
					</Form.Item>
					<Form.Item label={dynamicItem.type === 0 ? '图片' : '视频'} >
						{dynamicVisible ? showModalFile(dynamicItem) : ''}
					</Form.Item>
					<Form.Item label="审核" name="statusReview" initialValue={1}>
						<Radio.Group>
							<Radio value={1}>审核通过</Radio>
							<Radio value={2}>审核不通过</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item label="备注" name="remark" initialValue="">
						<TextArea rows={4}  maxLength={200} />
					</Form.Item>
				</Form>
			</Modal>
    </div>
  )
}