import {
  myAxios as axios
} from '../index'
import Qs from 'qs'

/**
 * 钓场列表
 * @param params
 * @returns {*}
 */
export const fishList = (params) => {
  return axios({
    // url: `/naerdiao/fishMessage/getPage`,
    url: `/naerdiao/fishMessage/getAdminPage`,
    data: params
  })
}

/**
 * 钓场管理员列表
 * @param params
 * @returns {*}
 */
export const getFishOperatorList = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/fishMessage/getFishOperatorList`,
    params
  })
}


/**
 * 新增钓场管理员
 * @param params
 * @returns {*}
 */
export const addFishOperator = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/fishMessage/addFishOperator`,
    params
  })
}

/**
 * 删除钓场管理员
 * @param params
 * @returns {*}
 */
export const deleteFishOperator = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/deleteFishOperator`,
    data: params
  })
}

/**
 * 保存钓场动态
 * @param params
 * @returns {*}
 */
export const getDynamicPage = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/getDynamicPage`,
    data: params
  })
}

/**
 * 保存钓场动态
 * @param params
 * @returns {*}
 */
export const addDynamic = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/addDynamic`,
    data: params
  })
}

/**
 * 获取省市区
 * @param params
 * @returns {*}
 */
export const getAllRegions = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/district/getAllRegions`,
    params
  })
}

/**
 * 上传文件 图片
 * @param params
 * @returns {*}
 */
export const uploadfile = (params) => {
  return axios({
    url: `/naerdiao/file/uploadfile?check=naerdiao`,
    data: params,
    transformRequest: [
      function (data) {
        return data
      },
    ]
  })
}

/**
 * 上传文件 视频
 * @param params
 * @returns {*}
 */
export const videoUpload = (params) => {
  return axios({
    url: `/naerdiao/file/videoUpload`,
    data: params,
    transformRequest: [
      function (data) {
        return data
      },
    ]
  })
}

/**
 * 删除图片
 * @param params
 * @returns {*}
 */
export const deleteFile = (params) => {
  return axios({
    url: `/naerdiao/file/deleteFile`,
    data: params,
    transformRequest: [
      function (data) {
        data = Qs.stringify(data)
        return data
      },
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

/**
 * 删除视频
 * @param params
 * @returns {*}
 */
export const deleteVideo = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/file/deleteVideo`,
    params
  })
}

/**
 * 查看视频
 * @param params
 * @returns {*}
 */
export const getVideoInfo = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/file/getVideoInfo`,
    params
  })
}

/**
 * 删除钓场动态
 * @param params
 * @returns {*}
 */
export const delDynamic = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/delDynamic`,
    data: params
  })
}

/**
 * 查看商店详情
 * @param params
 * @returns {*}
 */
export const fishMessage = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/fishMessage/${id}`
  })
}

/**
 * 新增商店信息
 * @param params
 * @returns {*}
 */
export const createFishMessage = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/createFishMessage`,
    data: params
  })
}

/**
 * 修改商店信息
 * @param params
 * @returns {*}
 */
export const updateFishMessage = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/updateFishMessage`,
    data: params
  })
}

/**
 * 获取鱼种
 * @param params
 * @returns {*}
 */
export const getFishCategory = () => {
  return axios({
    method: 'get',
    url: `/naerdiao/fishMessage/getFishCategory`
  })
}

/**
 * 根据坐标生成静态地图
 * @param params
 * @returns {*}
 */
export const getMapImgByLng = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/fishMessage/getMapImgByLng`,
    params
  })
}
