import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { commodityList, delCommodity } from '@/https/GoodsManage'
import { useDispatch } from 'react-redux'

export default function Page() {
  const dispatch = useDispatch()
	const [form] = Form.useForm()

	const [loading, setLoading] = useState('false')
	const [commodityData, setCommodityData] = useState('')

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await commodityList(params)
		setLoading(false)
		if (data.code === '00000') {
			setCommodityData(data.data)
		}
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
    getList(values)
	}

	// 删除商品
	const handleDel = (id) => {
    Modal.confirm({
      title: '是否确定删除！',
      centered: true,
      onOk: async () => {
        const { data } = await delCommodity(id)
        if (data.code === '00000') {
          message.success('删除成功')
          getList()
        } else {
          message.error(data.message)
        }
        return true
      }
    })
	}

	const columns = [
		{
			title: '商品名称',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
		},
		{
			title: '类别',
			dataIndex: 'type',
			key: 'type',
			render: (d) => <span>{d === 0 ? '虚拟' : '实物'}</span>,
		},
		{
			title: '单位',
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			title: '进价',
			dataIndex: 'enterPrice',
			key: 'enterPrice',
		},
		{
			title: '售价',
			dataIndex: 'sellPrice',
			key: 'sellPrice',
		},
		{
			title: '市场价',
			dataIndex: 'bazaarPrice',
			key: 'bazaarPrice',
		},
		{
			title: '商品数量',
			dataIndex: 'total',
			key: 'total',
		},
		{
			title: '库存',
			dataIndex: 'inventory',
			key: 'inventory',
		},
		{
			title: '兑换方式',
			dataIndex: 'exchangeType',
			key: 'exchangeType',
			render: (d) => <span>{d === 1 ? '直充' : '卡密'}</span>,
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 360,
			render: (text, record, index) => (
				<span>
          {
            record.debrisId ? <span>
              <Link to={`/home/GoodsManage/debris/look/${record.id}`}>
                <Button size="small" className="mr10" onClick={()=>{dispatch({ type: 'Detail', data: record })}}>
                  查看碎片
                </Button>
              </Link>
              <Link to={`/home/GoodsManage/debris/edit/${record.id}`}>
                <Button type="primary" size="small" className="mr10" onClick={()=>{dispatch({ type: 'Detail', data: record })}}>
                  修改碎片
                </Button>
              </Link>
            </span> : <Link to={`/home/GoodsManage/debris/add/${record.id}`}>
              <Button type="primary" size="small" className="mr10" onClick={()=>{dispatch({ type: 'Detail', data: record })}}>
                新增碎片
              </Button>
            </Link>
          }
					<Link to={`/home/GoodsManage/details/look/${record.id}`}>
						<Button type="primary" size="small" className="mr10" onClick={()=>{dispatch({ type: 'Detail', data: record })}}>
							查看
						</Button>
					</Link>
					<Link to={`/home/GoodsManage/details/edit/${record.id}`} onClick={()=>{dispatch({ type: 'Detail', data: record })}}>
						<Button type="primary" size="small" className="mr10">
							修改
						</Button>
					</Link>
					<Button
						type="primary"
            danger
						size="small"
						onClick={() => handleDel(record.id)}
					>
						删除
					</Button>
				</span>
			),
		},
	]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="name">
							<Input placeholder="商品名称" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="type" label="类别" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>虚拟</Select.Option>
								<Select.Option value={1}>实物</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
			<div className="public_operate">
				<Link to={`/home/GoodsManage/details/add/0`}>
					<Button type="primary">新增</Button>
				</Link>
			</div>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={commodityData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: commodityData.current,
						total: commodityData.total,
						showTotal: (total) => `共${commodityData.total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
		</div>
	)
}
