import {
  myAxios as axios
} from '../index'


/**
 * 用户反馈信息List
 * @param params
 * @returns {*}
 */
export const getAdminPage = (params) => {
  return axios({
    url: `/naerdiao/userFeedback/getAdminPage`,
    data: params
  })
}

/**
 * 后台用户反馈处理
 * @param params
 * @returns {*}
 */
export const toUserFeddback = (params) => {
  return axios({
    url: `/naerdiao/userFeedback/toUserFeddback`,
    data: params
  })
}
