import React, { useState, useEffect } from 'react'
import {
	Form,
	Row,
	Col,
	Input,
	Button,
	Table,
	Select,
	message,
	Modal,
} from 'antd'
import {
	skillClassList,
	skillClassDel,
	skillGetClassList,
  skillClassUpdate
} from '@/https/SkillManage'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'

export default function Page() {
	const [form] = Form.useForm()
	const dispatch = useDispatch()

	const [loading, setLoading] = useState('false')
	const [skillClassData, setSkillClassData] = useState('')

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
			level: 1,
		}
		let params = { ...option, ...param }
		const { data } = await skillClassList(params)
		setLoading(false)
		if (data.code === '00000') {
			data.data.list.filter((info) => {
				info.children = []
				return true
			})
			setSkillClassData(data.data)
		}
	}

	// 查询子分类
	const getClassList = async (options) => {
		setLoading(true)
		const { data } = await skillGetClassList({
			pid: options.id,
		})
    setLoading(false)
		if (data.code === '00000' && data.data.length) {
      data.data.filter((info) => {
				info.children = []
				return true
			})
			options.children = data.data
		} else {
			delete options.children
		}
		setSkillClassData({ ...skillClassData })
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

	// 展开行
	const onExpandedRowsChange = (data, row) => {
		console.log(data, row)
		if (data && row?.children.length === 0) {
			getClassList(row)
		}
	}

	// 删除技巧分类
	const handleDel = (id) => {
		Modal.confirm({
			title: '是否确定删除！',
			centered: true,
			onOk: async () => {
				const { data } = await skillClassDel(id)
				if (data.code === '00000') {
					message.success('删除成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			},
		})
	}

  // 启用禁用
  const handleChangeStatus = (id, status) => {
    Modal.confirm({
			title: `是否确定${status === 1 ? '启用' : '禁用'}！`,
			centered: true,
			onOk: async () => {
				const { data } = await skillClassUpdate({id, classStatus: status})
				if (data.code === '00000') {
					message.success(`${status === 1 ? '启用' : '禁用'}成功`)
					getList()
				} else {
					message.error(data.message)
				}
				return true
			},
		})
  }

	const columns = [
		{
			title: '分类名称',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
		},
		{
			title: '是否禁用',
			dataIndex: 'classStatus',
			key: 'classStatus',
			render: (d) => <span>{d === 0 ? '禁用' : '启用'}</span>,
		},
		{
			title: '修改时间',
			dataIndex: 'updateTime',
			key: 'updateTime',
			render: (d) => (
				<span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>
			),
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 200,
			render: (text, record, index) => (
				<span>
					<Link to={`/home/Skill/Classify/details/edit/${record.id}`}>
						<Button
							type="primary"
							size="small"
							className="mr10"
							onClick={() => {
								dispatch({ type: 'SkillDetail', data: record })
							}}
						>
							修改
						</Button>
					</Link>
          {
            record.classStatus === 0 && <Button
              type="primary"
              size="small"
              className="mr10"
              onClick={() => handleChangeStatus(record.id, 1)}
            >
              启用
            </Button>
          }
          {
            record.classStatus === 1 && <Button
              type="primary"
              danger
              size="small"
              className="mr10"
              onClick={() => handleChangeStatus(record.id, 0)}
            >
              禁用
            </Button>
          }
					<Button
						type="primary"
						danger
						size="small"
						onClick={() => handleDel(record.id)}
					>
						删除
					</Button>
				</span>
			),
		},
	]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="name">
							<Input placeholder="分类名称" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="classStatus"
							label="是否禁用"
							initialValue=""
						>
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>禁用</Select.Option>
								<Select.Option value={1}>启用</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
			<div className="public_operate">
				<Link to={`/home/Skill/Classify/details/add/0`}>
					<Button type="primary">新增</Button>
				</Link>
			</div>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={skillClassData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					expandable={{
						onExpand: onExpandedRowsChange,
					}}
					pagination={{
						current: skillClassData.current,
						total: skillClassData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
		</div>
	)
}
