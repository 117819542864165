import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal } from 'antd'
import { skillMainList, skillMainDelete, skillMainUpdate } from '@/https/SkillManage'
import { Link } from 'react-router-dom'

export default function Page() {
	const [form] = Form.useForm()

	const [loading, setLoading] = useState('false')
	const [skillData, setSkillData] = useState('')

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await skillMainList(params)
		setLoading(false)
		if (data.code === '00000') {
			setSkillData(data.data)
		}
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
    getList(values)
	}

	// 删除商品
	const handleDel = (id) => {
    Modal.confirm({
      title: '是否确定删除！',
      centered: true,
      onOk: async () => {
        const { data } = await skillMainDelete(id)
        if (data.code === '00000') {
          message.success('删除成功')
          getList()
        } else {
          message.error(data.message)
        }
        return true
      }
    })
	}

  // 修改状态， 发布、置顶
  const handleChangeStatus = (tip, param) => {
    Modal.confirm({
      title: `是否确定${tip}！`,
      centered: true,
      onOk: async () => {
        const { data } = await skillMainUpdate(param)
        if (data.code === '00000') {
          message.success(`${tip}成功`)
          getList()
        } else {
          message.error(data.message)
        }
        return true
      }
    })
  }

	const columns = [
		{
			title: '标题',
			dataIndex: 'title',
			key: 'title',
			fixed: 'left',
		},
    {
			title: '分类名称',
			dataIndex: 'className2',
			key: 'className2',
			fixed: 'left',
		},
    {
			title: '关键字',
			dataIndex: 'keyword',
			key: 'keyword',
		},
		{
			title: '封面图片数量',
			dataIndex: 'coverPictureNum',
			key: 'coverPictureNum',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (d) => <span>{d === 0 ? '未发布' : d === 1 ? '已发布' : d === 2 ? '已下架' : '---'}</span>,
		},
    {
			title: '置顶状态',
			dataIndex: 'isTop',
			key: 'isTop',
			render: (d) => <span>{d === 0 ? '未置顶' : d === 1 ? '已置顶' : d === 2 ? '取消置顶' : '---'}</span>,
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 340,
			render: (text, record, index) => (
				<span>
          <Link to={`/home/Skill/Contents/details/look/${record.id}`}>
						<Button
							size="small"
							className="mr10"
						>
							查看
						</Button>
					</Link>
          <Link to={`/home/Skill/Contents/details/edit/${record.id}`}>
						<Button
							type="primary"
							size="small"
							className="mr10"
						>
							修改
						</Button>
					</Link>
          {
            record.status === 0 && <Button
              type="primary"
              size="small"
              className="mr10"
              onClick={() => handleChangeStatus('发布',{id: record.id, status: 1})}
            >
              发布
            </Button>
          }
          {
            record.status === 1 && <Button
              size="small"
              className="mr10"
              onClick={() => handleChangeStatus('下架',{id: record.id, status: 2})}
            >
              下架
            </Button>
          }
          {
            (record.isTop === 0 || record.isTop === 2) && <Button
              type="primary"
              size="small"
              className="mr10"
              onClick={() => handleChangeStatus('置顶',{id: record.id, isTop: 1})}
            >
              置顶
            </Button>
          }
          {
            record.isTop === 1 && <Button
              size="small"
              className="mr10"
              onClick={() => handleChangeStatus('取消置顶',{id: record.id, isTop: 2})}
            >
              取消置顶
            </Button>
          }
          <Button
            type="primary"
            danger
            size="small"
            onClick={() => handleDel(record.id)}
          >
            删除
          </Button>
				</span>
			),
		},
	]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="title">
							<Input placeholder="标题" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="status" label="状态" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>未发布</Select.Option>
								<Select.Option value={1}>已发布</Select.Option>
                <Select.Option value={2}>已下架</Select.Option>
							</Select>
						</Form.Item>
					</Col>
          <Col span={8}>
						<Form.Item name="isTop" label="置顶状态" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>未置顶</Select.Option>
								<Select.Option value={1}>已置顶</Select.Option>
                <Select.Option value={2}>取消置顶</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
      <div className="public_operate">
				<Link to={`/home/Skill/Contents/details/add/0`}>
					<Button type="primary">新增</Button>
				</Link>
			</div>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={skillData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: skillData.current,
						total: skillData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
		</div>
	)
}
