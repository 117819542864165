// 省市区
const init = {
  districtData: null
}

const districtReducer = (state = init, action) => {
  switch (action.type) {
      case 'DISTRICTDATA':
          return {...state, districtData : action.data}
      default:
          return state
  }
}

export default districtReducer