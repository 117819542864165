const init = {
    detail: null
}

const homeReducer = (state = init, action) => {
    switch (action.type) {
        case 'Detail':
            return {...state, detail : action.data}
        default:
            return state
    }
}

export default homeReducer