import React, { useState, useEffect, useCallback } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	InputNumber,
	Select,
	Upload,
	message,
	Modal,
	Affix,
	Row,
	Col,
	Table,
	DatePicker,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	uploadfile,
	deleteFile,
	commodityList,
	fishList,
	activityAdd,
	activityDetails,
  activityUpdate
} from '@/https/ActivityManage'
import moment from 'moment'

export default function Page() {
	const { TextArea } = Input
	const { RangePicker } = DatePicker
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const [commodityForm] = Form.useForm()
	const [fishForm] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
	const districtData = useSelector((state) => state.District.districtData)
  const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxCover = 10

  const [detailData, setDetailData] = useState('')
	const [coverList, setCoverList] = useState([])
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [commodityData, setCommodityData] = useState('')
	const [selectedRows, setSelectedRows] = useState('')
	const [selectedCommodity, setSelectedCommodity] = useState('')
	const [isFishModalVisible, setIsFishModalVisible] = useState(false)
	const [fishData, setFishData] = useState('')
	const [selectedFishRows, setSelectedFishRows] = useState('')
	const [selectedFish, setSelectedFish] = useState('')
	const [provinceList, setProvinceList] = useState('')
	const [cityList, setCityList] = useState('')
	const [areaList, setAreaList] = useState('')

	// 查询详情
	const getDetail = useCallback(async () => {
		const { data } = await activityDetails(id)
		console.log(data)
		if (data.code === '00000') {
      const formData = onNeatenData(data.data)
      setDetailData(formData)
      form.setFieldsValue(formData)
		}
	}, [id, form])

  useEffect(() => {
		if (id*1) getDetail()
	}, [id, getDetail])

  // 图片表单数据处理 
  const onNeatenData = (data) => {
    let converForm = []
    if(data.coverUrl){
      const coverArr = data.coverUrl.split(',')
      coverArr.map((item, i)=>{
        converForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
      setCoverList(converForm)
    }
    if(data.commodityName){
      setSelectedCommodity({
        name: data.commodityName,
        id: data.commodityId
      })
      setSelectedRows([{
        name: data.commodityName,
        id: data.commodityId
      }])
      delete data.commodityName
    }
    if(data.placeName){
      setSelectedFish({
        name: data.placeName,
        id: data.placeId
      })
      setSelectedFishRows([{
        name: data.placeName,
        id: data.placeId
      }])
      delete data.placeName
    }
    const {
      name,
      price,
      productNum,
      maxmember,
      winnerMembers,
      backgroundNum,
      type,
      description,
			category
    } = data;
    return({
      name,
      price,
      productNum,
      maxmember,
      winnerMembers,
      backgroundNum,
      type,
      description,
			category,
      coverUrl: {fileList: converForm},
      activityTime: [moment(data.startTime), moment(data.lastTime)]
    })
  }

	const onFinish = async () => {
		const formData = await form.validateFields()
		console.log('Received values of form: ', formData)
		let coverData = []
		formData?.coverUrl?.fileList &&
			formData.coverUrl.fileList.map((item) => {
				coverData.push(item.url)
				return true
			})
		let [startTime, lastTime] = formData.activityTime
		const params = {
			...formData,
			coverUrl: coverData.join(','),
			commodityId: selectedCommodity.id,
			placeId: selectedFish.id,
			startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
			lastTime: moment(lastTime).format('YYYY-MM-DD HH:mm:ss'),
		}
		delete params.activityTime
    let postFun;
    if(operate === 'add'){
      postFun = activityAdd
    }else{
      postFun = activityUpdate
      params.id = id*1
    }
    const { data } = await postFun(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleCoverChange = ({ fileList }) => {
		setCoverList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setCoverList([...coverList])
			message.success('上传成功')
		} else {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setCoverList([...coverList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
		const { url, status } = file
		if (status === 'error') {
			return true
		}
		const { data } = await deleteFile({ filePath: url })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

	// 选择商品
	const showModal = (key) => {
    commodityForm.resetFields()
		setIsModalVisible(true)
		getCommodityList()
	}

  // 商品列表
  const getCommodityList = (param) => {
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    commodityList(params).then(({ data }) => {
			if (data.code === '00000') {
				setCommodityData(data.data)
			}
		})
  }

  // 查询商品
  const onCommodityFinish = (values) => {
    getCommodityList(values)
  }

  /**
	 * 分页
	 * @param page
	 */
	const handleCommodityChangePage = (page) => {
		const data = { current: page }
		getCommodityList(data)
	}

	const handleOk = () => {
		setSelectedCommodity(selectedRows[0])
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setSelectedRows([selectedCommodity])
		setIsModalVisible(false)
	}

	// 选择商品
	const rowSelection = {
		selectedRowKeys: selectedRows[0]?.id ? [selectedRows[0].id] : '',
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows)
		},
	}

	// 商品
	const columns = [
		{
			title: '商品名称',
			dataIndex: 'name',
			key: 'name',
		},
	]

	// 选择钓场
	const showFishModal = () => {
    fishForm.resetFields()
		setIsFishModalVisible(true)
		getFishList({provinceId: 1922, cityId: 1946})
	}

	useEffect(() => {
		if(districtData){
			setProvinceList(districtData)
		}
	}, [districtData])

	// 更换省份
	const handleProvinceChange = (id, item) => {
		if(id){
			const { key } = item
			setCityList(provinceList[key].cityList)
			form.setFieldsValue({cityId: '', areaId: ''})
		}
	}

	const handleCityChange = (id, item) => {
		if(id){
			const { key } = item
			setAreaList(cityList[key].areaList)
			form.setFieldsValue({areaId: ''})
		}
	}

	// 钓场列表
  const getFishList = (param) => {
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    fishList(params).then(({ data }) => {
			if (data.code === '00000') {
				setFishData(data.data)
			}
		})
  }

	useEffect(() => {
		if(provinceList && provinceList.length && isFishModalVisible){
			const checkPronce = provinceList.filter(info => info.id === 1922)
			setCityList(checkPronce[0].cityList)
			fishForm.setFieldsValue({provinceId: 1922, cityId: 1946})
		}
	}, [fishForm, provinceList, isFishModalVisible])

  // 钓场查询
  const onFishFinish = (values) => {
    getFishList(values)
  }

  /**
	 * 分页
	 * @param page
	 */
	const handleFishChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getFishList(data)
	}

	const handleFishOk = () => {
		setSelectedFish(selectedFishRows[0])
		setIsFishModalVisible(false)
	}

	const handleFishCancel = () => {
		setSelectedFishRows([selectedFish])
		setIsFishModalVisible(false)
	}

	// 选择钓场
	const rowFishSelection = {
		selectedRowKeys: selectedFishRows[0]?.id
			? [selectedFishRows[0].id]
			: '',
		onChange: (selectedRowKeys, selectedFishRows) => {
			setSelectedFishRows(selectedFishRows)
		},
	}

	// 钓场
	const columnsFish = [
		{
			title: '钓场名称',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
		},
		{
			title: '省份',
			dataIndex: 'provinceName',
			key: 'provinceName',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '市',
			dataIndex: 'cityName',
			key: 'cityName',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '电话',
			dataIndex: 'phone',
			key: 'phone',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '收费状态',
			dataIndex: 'payType',
			key: 'payType',
			render: (d) => <span>{d === 0 ? '计时' : d === 1 ? '记重' : d === 2 ? '混合' : d === 3 ? '套餐' : '免费'}</span>,
		},
		{
			title: '类别',
			dataIndex: 'type',
			key: 'type',
			render: (d) => <span>{d === 0 ? '钓场' : '渔具店'}</span>,
		}
	]

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => window.history.back()}
					title={
						operate === 'add'
							? '新增'
							: operate === 'edit'
							? '编辑'
							: '查看'
					}
					extra={[
						operate !== 'look' && (
							<Button type="primary" onClick={onFinish}>
								保存
							</Button>
						),
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form
					labelWrap
					{...layout}
					form={form}
					initialValues={
						operate === 'add'
							? ""
							: detailData
          }
				>
					<Form.Item
						name="name"
						label="活动标题"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input disabled={operate === 'look' ? true : false} />
					</Form.Item>
					<Form.Item name="category" label="中奖条件" initialValue={0}>
						<Select disabled={operate === 'look' ? true : false}>
							<Select.Option value={0}>时间</Select.Option>
							<Select.Option value={1}>人满</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="coverUrl" label="封面">
						<Upload
							disabled={operate === 'look' ? true : false}
							customRequest={upLoadFile}
							listType="picture-card"
							fileList={coverList}
							onPreview={handlePreview}
							onChange={handleCoverChange}
							onRemove={removeFile}
						>
							{coverList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item name="commodity" label="商品">
						<div>
							<Button
								type="primary"
								onClick={showModal}
								disabled={operate === 'look' ? true : false}
							>
								选择商品
							</Button>
							<span className="ml10">
								{selectedCommodity.name || '无'}
							</span>
						</div>
					</Form.Item>
					<Form.Item name="price" label="活动价格">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="productNum" label="商品数量上限">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="maxmember" label="人数上限">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="winnerMembers" label="中奖人数上限">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="backgroundNum" label="后台中奖数量">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="type" label="类型" initialValue={0}>
						<Select disabled={operate === 'look' ? true : false}>
							<Select.Option value={0}>无区域限制</Select.Option>
							<Select.Option value={1}>钓场活动</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="place" label="活动区域">
						<div>
							<Button
								type="primary"
								onClick={showFishModal}
								disabled={operate === 'look' ? true : false}
							>
								选择活动区域
							</Button>
							<span className="ml10">
								{selectedFish.name || '无'}
							</span>
						</div>
					</Form.Item>
					<Form.Item name="description" label="描述">
						<TextArea
							rows={4}
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="activityTime" label="活动时间">
						<RangePicker
							disabled={operate === 'look' ? true : false}
							showTime 
						/>
					</Form.Item>
				</Form>
			</div>
			<Modal
				title="商品"
				centered
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Form
					form={commodityForm}
					className="public_search_box"
					onFinish={onCommodityFinish}
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item name={`name`}>
								<Input placeholder="商品名称" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Button type="primary" htmlType="submit">
								查询
							</Button>
							<Button
								style={{ margin: '0 8px' }}
								onClick={() => {
									commodityForm.resetFields()
								}}
							>
								清空
							</Button>
						</Col>
					</Row>
				</Form>
				<Table
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						type: 'radio',
						...rowSelection,
					}}
					dataSource={commodityData.list}
					columns={columns}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: commodityData.current*1,
						total: commodityData.total*1,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleCommodityChangePage,
					}}
				/>
			</Modal>
			<Modal
				title="钓场区域"
				centered
				visible={isFishModalVisible}
				onOk={handleFishOk}
				onCancel={handleFishCancel}
				width={1000}
			>
				<Form
					form={fishForm}
					className="public_search_box"
					onFinish={onFishFinish}
				>
					<Row gutter={24}>
						<Col span={6}>
							<Form.Item name={`name`}>
								<Input placeholder="钓场名称" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="serviceType" label="类别" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									<Select.Option value={0}>钓场</Select.Option>
									<Select.Option value={1}>渔具店</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="type" label="类型" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									<Select.Option value={0}>黑坑</Select.Option>
									<Select.Option value={1}>野钓</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="provinceId" label="省" initialValue="">
								<Select onChange={handleProvinceChange}>
									<Select.Option value="">全国</Select.Option>
									{
										provinceList && provinceList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="cityId" label="市" initialValue="">
								<Select onChange={handleCityChange}>
									<Select.Option value="">全部</Select.Option>
									{
										cityList && cityList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="areaId" label="区" initialValue="">
								<Select>
									<Select.Option value="">全部</Select.Option>
									{
										areaList && areaList.map((item, index) => {
											return (
												<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
											)
										})
									}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Button type="primary" htmlType="submit">
								查询
							</Button>
							<Button
								style={{ margin: '0 8px' }}
								onClick={() => {
									fishForm.resetFields()
								}}
							>
								清空
							</Button>
						</Col>
					</Row>
				</Form>
				<Table
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						type: 'radio',
						...rowFishSelection,
					}}
					dataSource={fishData.list}
					columns={columnsFish}
					scroll={{ x: 'max-content'}}
					pagination={{
						current: fishData.current*1,
						total: fishData.total*1,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleFishChangePage,
					}}
				/>
			</Modal>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	)
}
