import store from './redux/store'
import { Provider } from 'react-redux'
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom'
import './App.css'

import Login from './pages/Login'
import Home from './pages/Home'

function App() {
	return (
		<Provider store={store}>
			<HashRouter>
				<Routes>
					<Route exact path="/" element={<Login/>} />
					<Route exact path="/*" element={<Home/>} />
          <Route exact path="*" element={<Navigate to="/" />} />
				</Routes>
			</HashRouter>
		</Provider>
	)
}

export default App
