import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Layout, Menu, message } from 'antd'
import * as Icon from '@ant-design/icons'
import MyRouter from '../Router'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getAllRegions } from '@/https/Common'
import '@/css/common.scss'
import './index.scss'

const { Header, Content, Sider } = Layout
const { SubMenu } = Menu

export default function Home() {
	let containerRef = useRef()
	const dispatch = useDispatch()
  const location = useLocation();

	const [collapsed, setCollapsed] = useState(false)
	const [selectKeys, setSelectKeys] = useState([])
	const [openKeys, setOpenKeys] = useState([])

	const getSelectKey = useCallback(() => {
    const rootSubmenuOpenKeys = ['Skill']
		const urlItem = location.pathname.split('/')
    if(rootSubmenuOpenKeys.includes(urlItem[2])){
      setOpenKeys([urlItem[2]])
      setSelectKeys([urlItem[3]])
    }else{
      setSelectKeys([urlItem[2]])
    }
	}, [location])

  useEffect(() => {
    getSelectKey()
  }, [getSelectKey])

  const onOpenChange = (openKeys) => {
    if(openKeys.length > 1){
      setOpenKeys(openKeys.slice(-1))
    }else{
      setOpenKeys(openKeys)
    }
  }

	useEffect(() => {
		// 获取省市区
		const getAreaList = async() => {
			const { data } = await getAllRegions()
			if(data.code === "00000"){
				const province = JSON.parse(data.data)
				dispatch({ type: 'DISTRICTDATA', data: province })
			}else{
				message.error('获取省市区失败')
			}
		}
    getAreaList()
  }, [dispatch])

	const menuData = [
		{
			url: '/home/ActivityManage',
			text: '活动管理',
			icon: 'UserOutlined',
		},
		{
			url: '/home/GoodsManage',
			text: '商品管理',
			icon: 'VideoCameraOutlined',
		},
		{
			url: '/home/OrderManage',
			text: '订单管理',
			icon: 'UploadOutlined',
		},
		{
			text: '技巧管理',
			icon: 'CalendarOutlined',
			children: [
				{
					url: '/home/Skill/Classify',
					text: '技巧分类',
				},
				{
					url: '/home/Skill/Contents',
					text: '技巧内容',
				},
			],
		},
    {
			url: '/home/OrbManage',
			text: '挖宝管理',
			icon: 'UploadOutlined',
		},
		{
			url: '/home/FishManage',
			text: '商家管理',
			icon: 'UploadOutlined',
		},
		{
			url: '/home/DynamicManage',
			text: '动态管理',
			icon: 'UploadOutlined',
		},
		{
			text: '用户管理',
			icon: 'UploadOutlined',
			children: [
				{
					url: '/home/User/Feedback',
					text: '用户反馈',
				}
			]
		},
		{
			url: '/home/LotteryManage',
			text: '抽奖管理',
			icon: 'UploadOutlined',
		},
	]

	useEffect(() => {
		dispatch({ type: 'AffIXREF', data: containerRef.current })
	}, [dispatch])

	const onCollapse = (collapsed) => {
		setCollapsed(collapsed)
	}

	const iconBC = (name) => {
		return React.createElement(Icon[name])
	}

	const renderMemuTotal = (data) => {
		return (
			data.length &&
			data.map((v, i) => {
				let subMenuKey =
					v.children && v.children.length > 0
						? v.children[0].url.split('/')[2]
						: v.url.split('/')[2]
				return v.children && v.children.length > 0 ? (
					<SubMenu
						key={subMenuKey}
						icon={v.icon ? iconBC(v.icon) : ''}
						title={v.text}
					>
						{v.children.map((m, j) => {
							const menuItemKey = m.url.split('/')[3]
							return (
								<Menu.Item key={menuItemKey}>
									<Link to={m.url}>{m.text}</Link>
								</Menu.Item>
							)
						})}
					</SubMenu>
				) : (
					<Menu.Item
						key={subMenuKey}
						icon={v.icon ? iconBC(v.icon) : ''}
					>
						<Link to={v.url}>{v.text}</Link>
					</Menu.Item>
				)
			})
		)
	}

	return (
		<Layout className="home_layout">
			<Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
				<div className="logo" />
				<Menu
					theme="dark"
					mode="inline"
					selectedKeys={selectKeys}
					openKeys={openKeys}
          onOpenChange={onOpenChange}>
					{renderMemuTotal(menuData)}
				</Menu>
			</Sider>
			<Layout className="home_right_layout">
				<Header className="home_header"></Header>
				<div className="home_content" ref={containerRef}>
					<Content>
						<MyRouter />
					</Content>
				</div>
			</Layout>
		</Layout>
	)
}
