import {
  myAxios as axios
} from '../index'
import Qs from 'qs'

/**
 * 挖宝列表
 * @param params
 * @returns {*}
 */
export const lotteryList = (params) => {
  return axios({
    url: `/naerdiao/lottery/list`,
    data: params
  })
}

/**
 * 上传文件
 * @param params
 * @returns {*}
 */
export const uploadfile = (params) => {
  return axios({
    url: `/naerdiao/file/uploadfile?check=naerdiao`,
    data: params,
    transformRequest: [
      function (data) {
        return data
      },
    ]
  })
}

/**
 * 删除图片
 * @param params
 * @returns {*}
 */
 export const deleteFile = (params) => {
  return axios({
    url: `/naerdiao/file/deleteFile`,
    data: params,
    transformRequest: [
      function (data) {
        data = Qs.stringify(data)
        return data
      },
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

/**
 * 商品列表
 * @param params
 * @returns {*}
 */
export const commodityList = (params) => {
  return axios({
    url: `/naerdiao/commodity/list`,
    data: params
  })
}

/**
 * 新增活动列表
 * @param params
 * @returns {*}
 */
export const addOrUpdateLottery = (params) => {
  return axios({
    url: `/naerdiao/lottery/addOrUpdateLottery`,
    data: params
  })
}

/**
 * 查询挖宝详情
 * @param params
 * @returns {*}
 */
export const lotteryDetails = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/lottery/${id}`
  })
}

/**
 * 发布挖宝信息
 * @param params
 * @returns {*}
 */
export const lotteryPublish = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/lottery/publish`,
    params
  })
}

/**
 * 删除挖宝信息
 * @param params
 * @returns {*}
 */
export const deleteLottery = (params) => {
  return axios({
    url: `/naerdiao/lottery/deleteLottery`,
    data: params
  })
}

/**
 * 关闭挖宝信息
 * @param params
 * @returns {*}
 */
export const lotteryClose = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/lottery/close`,
    params
  })
}

/**
 * 后台挖宝参与记录
 * @param params
 * @returns {*}
 */
 export const getLotteryUserPage = (params) => {
  return axios({
    url: `/naerdiao/lottery/getLotteryUserPage`,
    data: params
  })
}

/**
 * 后台挖宝中奖记录
 * @param params
 * @returns {*}
 */
 export const getLotteryWinningDetailPage = (params) => {
  return axios({
    url: `/naerdiao/lottery/getLotteryWinningDetailPage`,
    data: params
  })
}