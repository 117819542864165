import {
  myAxios as axios
} from '../index'


/**
 * 登录
 * @param params
 * @returns {*}
 */
export const loginToken = (params) => {
  return axios({
    url: `/naerdiao/authorize/token`,
    data: params
  })
}

