import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal } from 'antd'
import { lotteryList, lotteryPublish, deleteLottery, lotteryClose } from '@/https/LotteryManage'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function Page() {
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false)
	const [activityData, setActivityData] = useState('')

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
			type: 1
		}
		let params = { ...option, ...param }
		const { data } = await lotteryList(params)
		setLoading(false)
		if (data.code === '00000') {
			setActivityData(data.data)
		}
	}

	// 发布挖宝
	const handlePublish = (id) => {
		Modal.confirm({
			title: '是否确定发布！',
			centered: true,
			onOk: async () => {
				const { data } = await lotteryPublish({
					id
				})
				if (data.code === '00000') {
					message.success('发布成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			}
		})
	}

	// 关闭挖宝
	const handleClose = (id) => {
		Modal.confirm({
			title: '是否确定关闭！',
			centered: true,
			onOk: async () => {
				const { data } = await lotteryClose({
					id
				})
				if (data.code === '00000') {
					message.success('关闭成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			}
		})
	}

	// 删除挖宝
	const handleDel = (id) => {
		Modal.confirm({
			title: '是否确定删除！',
			centered: true,
			onOk: async () => {
				const { data } = await deleteLottery({
					ids: [id]
				})
				if (data.code === '00000') {
					message.success('删除成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			}
		})
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

	const columns = [
		{
			title: '抽奖名称',
			dataIndex: 'title',
			key: 'title',
			fixed: 'left',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (d) => <span>{d === 0 ? '待发布' : d === 1 ? '已发布' : d === 2 ? '活动中' : d === 3 ? '已关闭' : d}</span>,
		},
		{
			title: '售价',
			dataIndex: 'price',
			key: 'price',
		},
		{
			title: '活动开始时间',
			dataIndex: 'startTime',
			key: 'startTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '活动结束时间',
			dataIndex: 'endTime',
			key: 'endTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '修改时间',
			dataIndex: 'updateTime',
			key: 'updateTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 350,
			render: (text, record, index) => (
				<span>
					{
						record.status === 0 || record.status === 3 ? <Button
							type="primary"
							size="small"
							className="mr10"
							onClick={() => handlePublish(record.id)}
						>
							发布
						</Button> : ''
					}
					<Link
						to={`/home/LotteryManage/details/look/${record.id}`}
					>
						<Button size="small" className="mr10">
							查看
						</Button>
					</Link>
					{
						record.status === 1 || record.status === 2 ? <Button
							type="primary"
							size="small"
							className="mr10"
							onClick={() => handleClose(record.id)}
						>
							关闭
						</Button> : <Link to={`/home/LotteryManage/details/edit/${record.id}`}>
							<Button type="primary" size="small" className="mr10">
								修改
							</Button>
						</Link>
					}
					{
						record.status === 0 || record.status === 3 ? <Button
							type="primary"
							danger
							size="small"
							onClick={() => handleDel(record.id)}
						>
							删除
						</Button> : ''
					}
				</span>
			),
		},
	]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="title">
							<Input placeholder="抽奖名称" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="status" label="状态" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>待发布</Select.Option>
								<Select.Option value={1}>已发布</Select.Option>
								<Select.Option value={2}>活动中</Select.Option>
								<Select.Option value={3}>已关闭</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
			<div className="public_operate">
				<Link to={`/home/LotteryManage/details/add/0`}>
					<Button type="primary">新增</Button>
				</Link>
			</div>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={activityData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: activityData.current,
						total: activityData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
		</div>
	)
}
