import {
  myAxios as axios
} from '../index'
import Qs from 'qs'

/**
 * 技巧分类
 * @param params
 * @returns {*}
 */
export const skillClassList = (params) => {
  return axios({
    url: `/naerdiao/skillClass/list`,
    data: params
  })
}

/**
 * 技巧分类
 * @param params
 * @returns {*}
 */
export const skillGetClassList = (params) => {
  return axios({
    url: `/naerdiao/skillClass/getClassList`,
    data: params
  })
}

/**
 * 新增技巧分类
 * @param params
 * @returns {*}
 */
export const skillClassAdd = (params) => {
  return axios({
    url: `/naerdiao/skillClass/add`,
    data: params
  })
}

/**
 * 修改技巧分类
 * @param params
 * @returns {*}
 */
export const skillClassUpdate = (params) => {
  return axios({
    url: `/naerdiao/skillClass/update`,
    data: params
  })
}

/**
 * 删除技巧分类
 * @param id
 * @returns {*}
 */
export const skillClassDel = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/skillClass/${id}`
  })
}

/**
 * 上传文件
 * @param params
 * @returns {*}
 */
export const uploadfile = (params) => {
  return axios({
    url: `/naerdiao/file/uploadfile?check=naerdiao`,
    data: params,
    transformRequest: [
      function (data) {
        return data
      },
    ]
  })
}

/**
 * 删除图片
 * @param params
 * @returns {*}
 */
export const deleteFile = (params) => {
  return axios({
    url: `/naerdiao/file/deleteFile`,
    data: params,
    transformRequest: [
      function (data) {
        data = Qs.stringify(data)
        return data
      },
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}


/**
 * 技巧信息
 * @param params
 * @returns {*}
 */
export const skillMainList = (params) => {
  return axios({
    url: `/naerdiao/skillMain/list`,
    data: params
  })
}

/**
 * 技巧信息删除
 * @param params
 * @returns {*}
 */
export const skillMainDelete = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/skillMain/delete/${id}`
  })
}

/**
 * 技巧信息详情
 * @param params
 * @returns {*}
 */
export const skillMainView = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/skillMain/view/${id}`
  })
}

/**
 * 新增技巧信息
 * @param params
 * @returns {*}
 */
export const skillMainAdd = (params) => {
  return axios({
    url: `/naerdiao/skillMain/add`,
    data: params
  })
}

/**
 * 修改技巧信息
 * @param params
 * @returns {*}
 */
export const skillMainUpdate = (params) => {
  return axios({
    url: `/naerdiao/skillMain/update`,
    data: params
  })
}