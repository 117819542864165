import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal } from 'antd'
import { activityList, activityUpdate, userList, openAdminActivity } from '@/https/ActivityManage'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function Page() {
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false)
	const [activityData, setActivityData] = useState('')
  const [participationData, setParticipationData] = useState('')
  const [visible, setVisible] = useState(false)

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await activityList(params)
		setLoading(false)
		if (data.code === '00000') {
			setActivityData(data.data)
		}
	}

  // 删除活动
  const handleDel = (id) => {
    Modal.confirm({
      title: '是否确定删除！',
      centered: true,
      onOk: async () => {
        const { data } = await activityUpdate({
          id,
          deleted: 1
        })
        if (data.code === '00000') {
          message.success('删除成功')
          getList()
        } else {
          message.error(data.message)
        }
        return true
      }
    })
	}

  // 打开活动参与人弹框
  const handleParticipation = async (id) => {
    getParticipationList({activityId: id})
    setVisible(true)
  } 

  // 查询活动参与人
  const getParticipationList = async (param) => {
    setVisible(true)
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    const { data } = await userList(params)
    if (data.code === '00000') {
			setParticipationData(data.data)
		}
  }

	// 开奖
  const onLottery = (activityId) => {
		Modal.confirm({
      title: '是否确定立即开奖！',
      centered: true,
      onOk: async () => {
        const { data } = await openAdminActivity({
          activityId
        })
        if (data.code === '00000') {
          message.success('开奖成功')
          getList()
        } else {
          message.error(data.message)
        }
        return true
      }
    })
	}
  /**
	 * 分页
	 * @param page
	 */
	const handleChangeParticipationPage = (page) => {
    if(participationData?.list?.[0]?.activityId){
      const data = { current: page, activityId: participationData.list[0].activityId }
		  getParticipationList(data)
    }
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

	const columns = [
		{
			title: '活动标题',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
		},
		{
			title: '活动价格',
			dataIndex: 'price',
			key: 'price',
			render: (d) => <span>{d === 0 ? '免费' : d}</span>,
		},
		{
			title: '商品数量上限',
			dataIndex: 'productNum',
			key: 'productNum',
		},
		{
			title: '人数上限',
			dataIndex: 'maxmember',
			key: 'maxmember',
		},
		{
			title: '中奖人数上限',
			dataIndex: 'winnerMembers',
			key: 'winnerMembers',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
			render: (d) => <span>{d === 0 ? '无区域限制' : '钓场活动'}</span>,
		},
		{
			title: '活动开始时间',
			dataIndex: 'startTime',
			key: 'startTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '活动结束时间',
			dataIndex: 'lastTime',
			key: 'lastTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (d) => (
				<span>
					{d === 0 ? '未开始' : d === 1 ? '活动中' : '已结束'}
				</span>
			),
		},
		{
			title: '修改时间',
			dataIndex: 'modifyTime',
			key: 'modifyTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 330,
			render: (text, record, index) => (
				<span>
          <Button
						type="primary"
						size="small"
            className="mr10"
						onClick={() => handleParticipation(record.id)}
					>
						参与人
					</Button>
					{
						record.status === 1 && <Button
							type="primary"
							size="small"
							className="mr10"
							onClick={() => onLottery(record.id)}
						>
							开奖
						</Button>
					}
					<Link
						to={`/home/ActivityManage/details/look/${record.id}`}
					>
						<Button size="small" className="mr10">
							查看
						</Button>
					</Link>
					<Link to={`/home/ActivityManage/details/edit/${record.id}`}>
						<Button type="primary" size="small" className="mr10">
							修改
						</Button>
					</Link>
					<Button
						type="primary"
						danger
						size="small"
						onClick={() => handleDel(record.id)}
					>
						删除
					</Button>
				</span>
			),
		},
	]

  const participationColumns = [
    {
			title: '用户名称',
			dataIndex: 'userName',
			key: 'userName',
			fixed: 'left',
		},
    {
			title: '活动名称',
			dataIndex: 'activityName',
			key: 'activityName',
		},
    {
			title: '参与活动编号',
			dataIndex: 'activityNo',
			key: 'activityNo',
		},
		{
			title: '开奖状态',
			dataIndex: 'activityStatus',
			key: 'activityStatus',
      render: (d) => <span>{d === 0 ? '未开奖' : d === 1 ? '未中奖' : d === 3 ? '已中奖 ' : '---'}</span>,
		},
    {
			title: '参与时间',
			dataIndex: 'createTime',
			key: 'createTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		}
  ]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="name">
							<Input placeholder="活动名称" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="type" label="类别" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>
									无区域限制
								</Select.Option>
								<Select.Option value={1}>
									钓场活动
								</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="status" label="状态" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>未开始</Select.Option>
								<Select.Option value={1}>活动中</Select.Option>
								<Select.Option value={2}>已结束</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
			<div className="public_operate">
				<Link to={`/home/ActivityManage/details/add/0`}>
					<Button type="primary">新增</Button>
				</Link>
			</div>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={activityData.list}
					rowKey={(record) => record.id}
					columns={columns}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: activityData.current,
						total: activityData.total,
						showTotal: (total) => `共${activityData.total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
      <Modal
				visible={visible}
				title={'参与人'}
        width={900}
				footer={null}
        centered
				onCancel={() => {
          setVisible(false) 
          setParticipationData('')
        }}
			>
				<Table
					dataSource={participationData.list}
					columns={participationColumns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: participationData.current,
						total: participationData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangeParticipationPage,
					}}
				/>
			</Modal>
		</div>
	)
}
