import React, { useState, useEffect } from 'react'
import { getAdminPage, toUserFeddback } from '@/https/User'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal } from 'antd'

export default function Page() {
  const { TextArea } = Input
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
	const [feedbackData, setFeedbackData] = useState('')
  const [feedback, setFeedback] = useState('')
  const [resultVisible, setResultVisible] = useState(false)
  const [auditResult, setAuditResult] = useState('')

  useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await getAdminPage(params)
		setLoading(false)
		if (data.code === '00000') {
			setFeedbackData(data.data)
		}
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

  // 打开用户反馈处理
  const handleShowRecord = (record) => {
    setFeedback(record)
    setResultVisible(true)
  }

  // 保存处理结果
  const handleFeedbackOk = () => {
    toUserFeddback({
      id: feedback.id,
      auditResult
    }).then(res => {
      if(res.code === '00000'){
        getList()
        setAuditResult('')
        setResultVisible(false)
        message.success('提交成功')
      }else{
        message.error('提交失败')
      }
    })
  }

  const columns = [
		{
			title: '用户名',
			dataIndex: 'nickName',
			key: 'nickName',
			fixed: 'left'
		},
    {
			title: '手机号',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '邮箱',
			dataIndex: 'email',
			key: 'email',
			width: 120,
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '反馈描述',
			dataIndex: 'remarks',
			key: 'remarks',
			width: 120,
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (d) => <span>{d === 0 ? '待处理' : '已处理'}</span>,
		},
		{
			title: '审核结果',
			dataIndex: 'auditResult',
			key: 'auditResult',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 120,
			render: (text, record, index) => (
				<span>
					<Button size="small" className="mr10" onClick={() => handleShowRecord(record)}>
						反馈处理
					</Button>
				</span>
			),
		},
	]

  return(
    <div className="public_content_box">
      <Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={6}>
						<Form.Item name="nickName">
							<Input placeholder="用户名称" />
						</Form.Item>
					</Col>
          <Col span={6}>
						<Form.Item name="phone">
							<Input placeholder="手机号" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="status" label="状态" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>待处理</Select.Option>
								<Select.Option value={1}>已处理</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
      <div className="public_table">
				<Table
					loading={loading}
					dataSource={feedbackData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: feedbackData.current,
						total: feedbackData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
      <Modal
				visible={resultVisible}
				title={'反馈处理'}
				centered
        onOk={handleFeedbackOk}
				onCancel={() => {
          setAuditResult('')
          setResultVisible(false)
        }}
			>
				<Row gutter={[10,16]} wrap={false}>
          <Col flex="80px">反馈描述：</Col>
          <Col flex="auto">{feedback.remarks}</Col>
        </Row>
        <Row style={{marginTop: '16px'}}>
          <Col flex="80px">处理结果：</Col>
          <Col flex="auto">
            <TextArea
              value={auditResult}
							rows={4}
              maxLength={200}
              showCount
              onChange={(e)=>{
                setAuditResult(e.target.value)
              }}
						/>
          </Col>
        </Row>
			</Modal>
    </div>
  )
}