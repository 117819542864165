import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  fishList,
  getFishOperatorList,
  addFishOperator,
  deleteFishOperator,
  getAllRegions,
  deleteFile,
  deleteVideo,
  getDynamicPage,
  addDynamic,
  uploadfile,
  videoUpload,
  getVideoInfo,
  delDynamic
} from '@/https/FishManage'
import moment from 'moment'
import { Link } from 'react-router-dom'


export default function Page() {
  const { TextArea } = Input
  const [form] = Form.useForm()
  const [formDynamic] = Form.useForm()
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  }

  const [loading, setLoading] = useState(false)
  const [provinceList, setProvinceList] = useState('')
  const [cityList, setCityList] = useState('')
  const [areaList, setAreaList] = useState('')
  const [activityData, setActivityData] = useState('')
  const [fish, setFish] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [recordUserData, setRecordUserData] = useState('')
  const [administratorVisible, setAdministratorVisible] = useState(false)
  const [administratorMobile, setAdministratorMobile] = useState('')
  const [showDynamic, setShowDynamic] = useState(false)
  const [dynamicData, setDynamicData] = useState('')
  const [addShowDynamic, setAddShowDynamic] = useState(false)
  const [fileList, setFileList] = useState([])
  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewTitle, setPreviewTitle] = useState('')
  const [previewType, setPreviewType] = useState(0)

  useEffect(() => {
    getAreaList()
    getList({ provinceId: 1922, cityId: 1946 })
  }, [])

  const getList = async (param) => {
    setLoading(true)
    let option = {
      current: 1,
      pageSize: 10
    }
    let params = { ...option, ...param }
    const { data } = await fishList(params)
    setLoading(false)
    if (data.code === '00000') {
      setActivityData(data.data)
    }
  }

  /**
   * 分页
   * @param page
   */
  const handleChangePage = (page) => {
    const params = form.getFieldsValue()
    const data = { current: page, ...params }
    getList(data)
  }

  const onFinish = (values) => {
    getList(values)
  }

  useEffect(() => {
    if (provinceList && provinceList.length) {
      const checkPronce = provinceList.filter(info => info.id === 1922)
      setCityList(checkPronce[0].cityList)
      form.setFieldsValue({ provinceId: 1922, cityId: 1946 })
    }
  }, [form, provinceList])

  // 获取省市区
  const getAreaList = async () => {
    const { data } = await getAllRegions()
    if (data.code === '00000') {
      const province = JSON.parse(data.data)
      setProvinceList(province)
    } else {
      message.error('获取省市区失败')
    }
  }

  // 更换省份
  const handleProvinceChange = (id, item) => {
    if (id) {
      const { key } = item
      setCityList(provinceList[key].cityList)
      form.setFieldsValue({ cityId: '', areaId: '' })
    }
  }

  const handleCityChange = (id, item) => {
    if (id) {
      const { key } = item
      setAreaList(cityList[key].areaList)
      form.setFieldsValue({ areaId: '' })
    }
  }

  // 打开钓场管理员
  const handleShowRecord = (record) => {
    setFish({ ...record })
    setIsModalVisible(true)
    getUserList({ id: record.id })
  }

  // 钓场管理员
  const getUserList = async (param) => {
    const { data } = await getFishOperatorList(param)
    if (data.code === '00000') {
      setRecordUserData(data.data)
    }
  }

  // 新增管理员
  const handleOk = async () => {
    const { data } = await addFishOperator({
      id: fish.id,
      phone: administratorMobile
    })
    if (data.code === '00000') {
      message.success('新增成功')
      getUserList({ id: fish.id })
      setAdministratorVisible(false)
      setAdministratorMobile('')
    } else {
      message.error('新增失败')
    }
  }

  // 删除管理员
  const handleDel = (userId) => {
    Modal.confirm({
      title: '是否确定删除！',
      centered: true,
      onOk: async () => {
        const { data } = await deleteFishOperator({
          id: fish.id,
          userId
        })
        if (data.code === '00000') {
          message.success('删除成功')
          getUserList({ id: fish.id })
        } else {
          message.error(data.message)
        }
        return true
      }
    })
  }

  // 打开动态
  const handleShowDynamic = (record) => {
    setFish({ ...record })
    setShowDynamic(true)
    getDynamicList({ sourceId: record.id })
  }

  // 获取钓场动态
  const getDynamicList = async (param) => {
    let option = {
      current: 1,
      pageSize: 10
    }
    let params = { ...option, ...param }
    const { data } = await getDynamicPage(params)
    if (data.code === '00000') {
      setDynamicData(data.data)
    }
  }

  /**
   * 分页
   * @param page
   */
  const handleDynamicChangePage = (page) => {
    const params = formDynamic.getFieldsValue()
    const data = { current: page, sourceId: fish.id, ...params }
    getDynamicList(data)
  }

  // 图片文件处理
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    if (file.type.split('/')[0] === 'video') {
      const { data } = await getVideoInfo({ videoId: file.url })
      setPreviewImage(data.data.PlayInfoList.PlayInfo[0].PlayURL)
      setPreviewType(2)
    } else {
      setPreviewType(0)
      setPreviewImage(file.url || file.preview)
    }
    setPreviewTitle(file.name)
    setPreviewVisible(true)
  }

  const handleCoverChange = ({ fileList }) => {
    setFileList(fileList)
  }

  // 上传文件
  const upLoadFile = async (u) => {
    const { uid, type } = u.file
    let formData = new FormData()
    formData.append('file', u.file)
    let result
    if (type.split('/')[0] === 'video') {
      const { data } = await videoUpload(formData)
      result = data
    } else {
      const { data } = await uploadfile(formData)
      result = data
    }
    const {
      code, data
    } = result
    if (code === '00000') {
      fileList.filter((info) => {
        if (info.uid === uid) {
          info.url = type.split('/')[0] === 'video' ? data.videoId : data
          info.coverPictureUrl = data.coverPictureUrl
          info.status = 'done'
        }
        return true
      })
      setFileList([...fileList])
      message.success('上传成功')
    } else {
      fileList.filter((info) => {
        if (info.uid === uid) {
          info.status = 'error'
        }
        return true
      })
      setFileList([...fileList])
      message.error(result.message)
    }
  }

  // 删除文件
  const removeFile = async (file) => {
    const { url, status, type, coverPictureUrl } = file
    if (status === 'error') {
      return true
    }
    // deleteVideo
    let result
    if (type.split('/')[0] === 'video') {
      const { data } = await deleteVideo({ videoId: url })
      result = data
      deleteFile({ filePath: coverPictureUrl })
    } else {
      const { data } = await deleteFile({ filePath: url })
      result = data
    }
    if (result.code === '00000') {
      message.success('删除成功')
      return true
    } else {
      message.error(result.message)
      return false
    }
  }

  // 新增保存动态
  const handleDynamicOk = async () => {
    const formData = formDynamic.getFieldsValue(true)
    let {
      title,
      description,
      url
    } = formData
    let fileResult, fileType
    if (url && url.file) {
      const { url: result, type: ft, status } = formData.url.file
      fileResult = result
      fileType = ft
      if (status === 'error') {
        message.error('上传文件失败，请重新上传文件')
      }
    } else {
      message.error('请上传文件')
    }
    let params = { title, description, sourceId: fish.id }
    if (fileType.split('/')[0] === 'video') {
      params.type = 2
      params.videoId = fileResult
    } else {
      params.type = 0
      params.url = fileResult
    }

    const { data } = await addDynamic(params)
    if (data.code === '00000') {
      message.success('保存成功')
      getDynamicList({ sourceId: fish.id })
      formDynamic.resetFields()
      setAddShowDynamic(false)
    } else {
      message.error(data.message)
    }
  }

  // 删除动态
  const handleDelDynamic = async (id) => {
    const { data } = await delDynamic({ id })
    if (data.code === '00000') {
      getDynamicList({ sourceId: fish.id })
    }
  }

  const columns = [
    {
      title: '钓场名称',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 300
    },
    {
      title: '省份',
      dataIndex: 'provinceName',
      key: 'provinceName',
      width: 120,
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '市',
      dataIndex: 'cityName',
      key: 'cityName',
      width: 120,
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '电话',
      dataIndex: 'phone',
      key: 'phone',
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '收费状态',
      dataIndex: 'payType',
      key: 'payType',
      render: (d) => <span>{d === 0 ? '计时' : d === 1 ? '记重' : d === 2 ? '混合' : d === 3 ? '套餐' : '免费'}</span>
    },
    {
      title: '类别',
      dataIndex: 'type',
      key: 'type',
      render: (d) => <span>{d === 0 ? '钓场' : '渔具店'}</span>
    },
    {
      title: '收费标准',
      dataIndex: 'payStandard',
      key: 'payStandard',
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '负责人',
      dataIndex: 'principal',
      key: 'principal',
      width: 120,
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '水域面积',
      dataIndex: 'area',
      key: 'area',
      width: 120,
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '最高水深',
      dataIndex: 'depth',
      key: 'depth',
      width: 120,
      render: (d) => <span>{d ? d : '---'}</span>
    },
    {
      title: '最高水深',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span>{status === 0 ? '正常' : status === 1 ? '待审核' : status === 2 ? '审核失败' : '---'}</span>
    },
    {
      title: '操作',
      key: 'operation',
      fixed: 'right',
      width: 320,
      render: (text, record, index) => (
        <span>
					<Link
            to={`/home/FishManage/details/look/${record.id}`}
          >
						<Button size="small" className="mr10">
							查看
						</Button>
					</Link>
					<Link to={`/home/FishManage/details/edit/${record.id}`}>
						<Button type="primary" size="small" className="mr10">
							修改
						</Button>
					</Link>
					<Button size="small" className="mr10" onClick={() => handleShowRecord(record)}>
						查看管理员
					</Button>
					<Button size="small" onClick={() => handleShowDynamic(record)}>
						查看动态
					</Button>
				</span>
      )
    }
  ]

  const recordUserColumns = [
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (d) => <span>{d || '---'}</span>
    },
    {
      title: '联系电话',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (d) => <span>{d || '---'}</span>
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>
    }, {
      title: '操作',
      key: 'operation',
      fixed: 'right',
      width: 120,
      render: (text, record, index) => (
        <span>
					<Button
            type="primary"
            danger
            size="small"
            onClick={() => handleDel(record.id)}
          >
						删除
					</Button>
				</span>
      )
    }
  ]

  const dynamicColumns = [{
    title: '标题',
    dataIndex: 'title',
    key: 'title'
  }, {
    title: '文件类型',
    dataIndex: 'type',
    key: 'type',
    render: (d) => <span>{d === 0 ? '图片' : d === 1 ? '普通视频' : '小视频'}</span>
  }, {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>
  }, {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    width: 120,
    render: (text, record, index) => (
      <span>
				<Button
          type="primary"
          danger
          size="small"
          onClick={() => handleDelDynamic(record.id)}
        >
					删除
				</Button>
			</span>
    )
  }]

  return (
    <div className="public_content_box">
      <Form form={form} className="public_search_box" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="name">
              <Input placeholder="钓场名称"/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="serviceType" label="类别" initialValue="">
              <Select>
                <Select.Option value="">全部</Select.Option>
                <Select.Option value={0}>钓场</Select.Option>
                <Select.Option value={1}>渔具店</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="type" label="类型" initialValue="">
              <Select>
                <Select.Option value="">全部</Select.Option>
                <Select.Option value={0}>黑坑</Select.Option>
                <Select.Option value={1}>野钓</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="provinceId" label="省" initialValue="">
              <Select onChange={handleProvinceChange}>
                <Select.Option value="">全国</Select.Option>
                {
                  provinceList && provinceList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="cityId" label="市" initialValue="">
              <Select onChange={handleCityChange}>
                <Select.Option value="">全部</Select.Option>
                {
                  cityList && cityList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="areaId" label="区" initialValue="">
              <Select>
                <Select.Option value="">全部</Select.Option>
                {
                  areaList && areaList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="status" label="状态" initialValue="">
              <Select>
                <Select.Option value="">全部</Select.Option>
                <Select.Option value={0}>正常</Select.Option>
                <Select.Option value={1}>待审核</Select.Option>
                <Select.Option value={2}>审核失败</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                form.resetFields()
              }}
            >
              清空
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="public_operate">
        <Link to={`/home/FishManage/details/add/0`}>
          <Button type="primary">新增</Button>
        </Link>
      </div>
      <div className="public_table">
        <Table
          loading={loading}
          dataSource={activityData.list}
          columns={columns}
          rowKey={(record) => record.id}
          locale={{ emptyText: '暂无数据' }}
          scroll={{ x: 'max-content' }}
          pagination={{
            current: activityData.current,
            total: activityData.total,
            showTotal: (total) => `共${total}条`,
            showTitle: false,
            showSizeChanger: false,
            onChange: handleChangePage
          }}
        />
      </div>
      <Modal
        title="钓场管理员"
        centered
        width={1000}
        visible={isModalVisible}
        maskClosable={false}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="public_operate" style={{ marginBottom: '15px' }}>
          <Button type="primary" onClick={() => setAdministratorVisible(true)}>新增</Button>
        </div>
        <Table
          size="small"
          rowKey={(record) => record.id}
          dataSource={recordUserData}
          columns={recordUserColumns}
          pagination={false}
        />
      </Modal>
      <Modal
        title="新增管理员"
        centered
        visible={administratorVisible}
        maskClosable={false}
        onOk={handleOk}
        onCancel={() => {
          setAdministratorVisible(false)
          setAdministratorMobile('')
        }}
      >
        <Input placeholder="手机号" value={administratorMobile} maxLength={11} onChange={(e) => {
          setAdministratorMobile(e.target.value)
        }}/>
      </Modal>
      <Modal
        title="钓场动态"
        centered
        width={1000}
        visible={showDynamic}
        maskClosable={false}
        footer={null}
        onCancel={() => setShowDynamic(false)}
      >
        <div className="public_operate" style={{ marginBottom: '15px' }}>
          <Button type="primary" onClick={() => setAddShowDynamic(true)}>新增</Button>
        </div>
        <Table
          size="small"
          rowKey={(record) => record.id}
          dataSource={dynamicData.list}
          columns={dynamicColumns}
          pagination={{
            current: dynamicData.current,
            total: dynamicData.total,
            showTotal: (total) => `共${total}条`,
            showTitle: false,
            showSizeChanger: false,
            onChange: handleDynamicChangePage
          }}
        />
      </Modal>
      <Modal
        title="新增动态"
        centered
        visible={addShowDynamic}
        maskClosable={false}
        onOk={handleDynamicOk}
        onCancel={() => {
          setAddShowDynamic(false)
        }}
      >
        <Form form={formDynamic} {...layout}>
          <Form.Item label="动态标题" name="title" rules={[{ required: true }]}>
            <Input placeholder="动态标题"/>
          </Form.Item>
          <Form.Item label="介绍" name="description">
            <TextArea
              rows={4}
            />
          </Form.Item>
          <Form.Item name="url" label="文件">
            <Upload
              customRequest={upLoadFile}
              fileList={fileList}
              listType="picture"
              onChange={handleCoverChange}
              onPreview={handlePreview}
              onRemove={removeFile}
              maxCount={1}
            >
              <Button icon={<UploadOutlined/>}>Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        centered
        onCancel={() => setPreviewVisible(false)}
      >
        {
          previewType === 2 ? <video width="472" height="600" controls>
            {/* <source src="movie.ogg" type="video/ogg"/> */}
            <source src={previewImage} type="video/mp4"/>
            {/* <source src="movie.webm" type="video/webm"/> */}
            <object data={previewImage} width="472" height="300">
              {/* <embed width="472" height="600" src="movie.swf"/> */}
            </object>
          </video> : <img
            alt="example"
            style={{ width: '100%' }}
            src={previewImage}
          />
        }
      </Modal>
    </div>
  )
}
