const init = {
    skillDetail: null
}

const indexReducer = (state = init, action) => {
    switch (action.type) {
        case 'SkillDetail':
            return {...state, SkillDetail : action.data}
        default:
            return state
    }
}

export default indexReducer