import React, { useState, useEffect, useCallback } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	Upload,
	InputNumber,
	Affix,
	Modal,
	message,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	uploadfile,
	deleteFile,
  getByCommodityId,
	addCommodityDebris,
  updateCommodityDebris
} from '@/https/GoodsManage'

export default function Page() {
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
	const goodsData = useSelector((state) => state.GoodsManage.detail)
  const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxCover = 10

  const [detailData, setDetailData] = useState('')
	const [coverList, setCoverList] = useState([])
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')

  // 查询详情
	const getDetail = useCallback(async () => {
		const { data } = await getByCommodityId({commodityId: id})
		console.log(data)
		if (data.code === '00000') {
      const formData = onNeatenData(data.data)
      setDetailData(formData)
      form.setFieldsValue(formData)
		}
	}, [id, form])

  useEffect(() => {
		if (id) getDetail()
	}, [id, getDetail])

	const onFinish = async () => {
		const formData = await form.validateFields()
		let coverData = []
		formData?.cover?.fileList &&
			formData.cover.fileList.map((item) => {
				coverData.push(item.url)
				return true
			})
		const params = {
			...formData,
      commodityId: id*1,
			cover: coverData.join(',')
		}
    let postFun;
    if(operate === 'add'){
      postFun = addCommodityDebris
    }else{
      postFun = updateCommodityDebris
      params.id = detailData.id
    }
    const { data } = await postFun(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleCoverChange = ({ fileList }) => {
		setCoverList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setCoverList([...coverList])
			message.success('上传成功')
		} else {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setCoverList([...coverList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
		const { url, status } = file
		if (status === 'error') {
			return true
		}
		const { data } = await deleteFile({ filePath: url })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

  // 图片表单数据处理
  const onNeatenData = (data) => {
    let converForm = []
    if(data.cover){
      const coverArr = data.cover.split(',')
      coverArr.map((item, i)=>{
        converForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
      setCoverList(converForm)
    }
    return {
      ...data,
      cover: {fileList: converForm}
    }
  }

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => navigate(-1)}
					title={
						operate === 'add'
							? `新增${goodsData.name}碎片`
							: operate === 'edit'
							? `编辑${goodsData.name}碎片`
							: `查看${goodsData.name}碎片`
					}
					extra={[
						operate !== 'look' && <Button type="primary" onClick={onFinish}>
							保存
						</Button>
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form
					labelWrap
					{...layout}
					form={form}
				>
					<Form.Item
						name="debrisName"
						label="碎片名称"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="cover" label="商品碎片图片地址">
						<Upload
              disabled={operate === 'look' ? true : false}
							customRequest={upLoadFile}
							listType="picture-card"
							fileList={coverList}
							onPreview={handlePreview}
							onChange={handleCoverChange}
							onRemove={removeFile}
						>
							{coverList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item name="syntheticNum" label="碎片合成数量">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="onceObtainNum" label="单次最多获取数量">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
					<Form.Item name="dailyObtainNum" label="每日获取最多数量">
						<InputNumber disabled={operate === 'look' ? true : false}/>
					</Form.Item>
				</Form>
			</div>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	)
}
