import {combineReducers} from 'redux'
import home from './home'
import GoodsManage from './GoodsManage'
import SkillManage from './SkillManage'
import District from './District'

const reducer = combineReducers({
  home,
  GoodsManage,
  SkillManage,
  District
})
export default reducer