import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Table, Select, message, Modal, Tabs, DatePicker } from 'antd'
import { lotteryList, lotteryPublish, deleteLottery, lotteryClose, getLotteryUserPage, getLotteryWinningDetailPage } from '@/https/OrbManage'
import { Link } from 'react-router-dom'
import moment from 'moment'

const { TabPane } = Tabs;

export default function Page() {
	const [form] = Form.useForm()
	const [recordUserForm] = Form.useForm()
	const [recordWinninForm] = Form.useForm()
	const { RangePicker } = DatePicker

	const [loading, setLoading] = useState(false)
	const [activityData, setActivityData] = useState('')
	const [lottery, setLottery] = useState('')
	const [recordKey, setRecordKey] = useState('1')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [recordUserData, setRecordUserData] = useState('')
	const [recordWinningData, setRecordWinningData] = useState('')

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
			type: 0
		}
		let params = { ...option, ...param }
		const { data } = await lotteryList(params)
		setLoading(false)
		if (data.code === '00000') {
			setActivityData(data.data)
		}
	}

	// 发布挖宝
	const handlePublish = (id) => {
		Modal.confirm({
			title: '是否确定发布！',
			centered: true,
			onOk: async () => {
				const { data } = await lotteryPublish({
					id
				})
				if (data.code === '00000') {
					message.success('发布成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			}
		})
	}

	// 关闭挖宝
	const handleClose = (id) => {
		Modal.confirm({
			title: '是否确定关闭！',
			centered: true,
			onOk: async () => {
				const { data } = await lotteryClose({
					id
				})
				if (data.code === '00000') {
					message.success('关闭成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			}
		})
	}

	// 删除挖宝
	const handleDel = (id) => {
		Modal.confirm({
			title: '是否确定删除！',
			centered: true,
			onOk: async () => {
				const { data } = await deleteLottery({
					ids: [id]
				})
				if (data.code === '00000') {
					message.success('删除成功')
					getList()
				} else {
					message.error(data.message)
				}
				return true
			}
		})
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

	// 打开记录
	const handleShowRecord = (record) => {
		setLottery(record)
		setRecordKey('1')
		setIsModalVisible(true)
		getUserList({lotteryId: record.id})
	}

	// 参与记录
	const getUserList = async (param) => {
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await getLotteryUserPage(params)
		if (data.code === '00000') {
			setRecordUserData(data.data)
		}
	}

	// 查询参与记录
	const onRecordUserFinish = (values) => {
		let [startTime, endTime] = values.createTime
		delete values.createTime
		getUserList({...values, lotteryId: lottery.id, startTime: startTime && moment(startTime).format('YYYY-MM-DD'), endTime: endTime && moment(endTime).format('YYYY-MM-DD')})
	}

	// 中奖记录
	const getWinningList = async (param) => {
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await getLotteryWinningDetailPage(params)
		if (data.code === '00000') {
			setRecordWinningData(data.data)
		}
	}

	// 查询中奖记录
	const onRecordWinninFinish = (values) => {
		let [startTime, endTime] = values.winningTime
		delete values.winningTime
		getWinningList({...values, lotteryId: lottery.id, startTime: startTime && moment(startTime).format('YYYY-MM-DD'), endTime: endTime && moment(endTime).format('YYYY-MM-DD')})
	}

	/**
	 * 分页
	 * @param page
	 */
	const handlerecordUserChangePage = (page) => {
		const data = { lotteryId: lottery.id, current: page }
		getUserList(data)
	}

	/**
	 * 分页
	 * @param page
	 */
	const handlerecordWinnChangePage = (page) => {
		const data = { lotteryId: lottery.id, current: page }
		getWinningList(data)
	}

	// 记录tab切换
	const callback = (key) => {
		setRecordKey(key)
		console.log('key :>> ', key);
		if(key === '1'){
			getUserList({lotteryId: lottery.id})
		}else{
			getWinningList({lotteryId: lottery.id})
		}
	}

	const columns = [
		{
			title: '挖宝名称',
			dataIndex: 'title',
			key: 'title',
			fixed: 'left',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			render: (d) => <span>{d === 0 ? '待发布' : d === 1 ? '已发布' : d === 2 ? '活动中' : d === 3 ? '已关闭' : d}</span>,
		},
		{
			title: '每天抽奖次数',
			dataIndex: 'defaultNum',
			key: 'defaultNum',
			render: (d) => <span>{d ? d : '无上限'}</span>,
		},
		{
			title: '一次金币数量',
			dataIndex: 'goldNum',
			key: 'goldNum',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
		{
			title: '用户中奖上限',
			dataIndex: 'userWinningCeiling',
			key: 'userWinningCeiling',
			render: (d) => <span>{d ? d : '无上限'}</span>,
		},
		{
			title: '挖宝开始时间',
			dataIndex: 'startTime',
			key: 'startTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '挖宝结束时间',
			dataIndex: 'endTime',
			key: 'endTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '修改时间',
			dataIndex: 'updateTime',
			key: 'updateTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 350,
			render: (text, record, index) => (
				<span>
					<Button size="small" className="mr10" onClick={() => handleShowRecord(record)}>
						查看记录
					</Button>
					{
						record.status === 0 || record.status === 3 ? <Button
							type="primary"
							size="small"
							className="mr10"
							onClick={() => handlePublish(record.id)}
						>
							发布
						</Button> : ''
					}
					<Link
						to={`/home/OrbManage/details/look/${record.id}`}
					>
						<Button size="small" className="mr10">
							查看
						</Button>
					</Link>
					{
						record.status === 1 || record.status === 2 ? <Button
							type="primary"
							size="small"
							className="mr10"
							onClick={() => handleClose(record.id)}
						>
							关闭
						</Button> : <Link to={`/home/OrbManage/details/edit/${record.id}`}>
							<Button type="primary" size="small" className="mr10">
								修改
							</Button>
						</Link>
					}
					{
						record.status === 0 || record.status === 3 ? <Button
							type="primary"
							danger
							size="small"
							onClick={() => handleDel(record.id)}
						>
							删除
						</Button> : ''
					}
				</span>
			),
		},
	]

	const recordUserColumns = [
		{
			title: '手机号',
			dataIndex: 'mobile',
			key: 'mobile',
		},
		{
			title: '商品名称',
			dataIndex: 'commodityName',
			key: 'commodityName',
			render: (d) => <span>{ d || '---'}</span>,
		},
		{
			title: '开奖状态',
			dataIndex: 'status',
			key: 'status',
			render: (d) => <span>{d === 0 ? '未中奖' : d === 1 ? '已中奖' : '中碎片'}</span>,
		},
		{
			title: '参与时间',
			dataIndex: 'createTime',
			key: 'createTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '中奖数量',
			dataIndex: 'number',
			key: 'number',
			render: (d) => <span>{d ? d : '---'}</span>,
		},
	]

	const recordWinnColumns = [
		{
			title: '用户名称',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: '商品名称',
			dataIndex: 'commodityName',
			key: 'commodityName',
			render: (d) => <span>{ d || '---'}</span>,
		},
		{
			title: '中奖类型',
			dataIndex: 'type',
			key: 'type',
			render: (d) => <span>{d === 0 ? '真实用户' : '虚拟生成'}</span>,
		},
		{
			title: '抽奖时间',
			dataIndex: 'winningTime',
			key: 'winningTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '领取时间',
			dataIndex: 'getTime',
			key: 'getTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '时效时间',
			dataIndex: 'expirationTime',
			key: 'expirationTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
	]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="title">
							<Input placeholder="挖宝名称" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="status" label="状态" initialValue="">
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>待发布</Select.Option>
								<Select.Option value={1}>已发布</Select.Option>
								<Select.Option value={2}>活动中</Select.Option>
								<Select.Option value={3}>已关闭</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
			<div className="public_operate">
				<Link to={`/home/OrbManage/details/add/0`}>
					<Button type="primary">新增</Button>
				</Link>
			</div>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={activityData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: activityData.current,
						total: activityData.total,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
			<Modal
				title="记录"
				centered
				width={1000}
				visible={isModalVisible}
				maskClosable={false}
				footer={null}
				onCancel={() => setIsModalVisible(false)}
			>
				<Tabs onChange={callback} activeKey={recordKey} type="card">
					<TabPane tab="参与记录" key='1'>
						<Form
							form={recordUserForm}
							className="public_search_box"
							onFinish={onRecordUserFinish}
						>
							<Row gutter={24}>
								<Col span={8}>
									<Form.Item name={`mobile`}>
										<Input placeholder="手机号" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name={`commodityName`}>
										<Input placeholder="商品名称" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="status" label="状态" initialValue="">
										<Select>
											<Select.Option value="">全部</Select.Option>
											<Select.Option value={0}>未中奖</Select.Option>
											<Select.Option value={1}>已中奖</Select.Option>
											<Select.Option value={2}>中碎片</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="createTime" label="参与时间" initialValue="">
										<RangePicker/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{ margin: '0 8px' }}
										onClick={() => {
											recordUserForm.resetFields()
										}}
									>
										清空
									</Button>
								</Col>
							</Row>
						</Form>
						<Table
							size="small"
							rowKey={(record) => record.id}
							dataSource={recordUserData.list}
							columns={recordUserColumns}
							pagination={{
								current: recordUserData.current * 1,
								total: recordUserData.total * 1,
								showTotal: (total) => `共${total}条`,
								showTitle: false,
								showSizeChanger: false,
								onChange: handlerecordUserChangePage,
							}}
						/>
					</TabPane>
					<TabPane tab="中奖记录" key='2'>
						<Form
							form={recordWinninForm}
							className="public_search_box"
							onFinish={onRecordWinninFinish}
						>
							<Row gutter={24}>
								<Col span={8}>
									<Form.Item name={`mobile`}>
										<Input placeholder="用户名称" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name={`commodityName`}>
										<Input placeholder="商品名称" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="type" label="中奖类型" initialValue="">
										<Select>
											<Select.Option value="">全部</Select.Option>
											<Select.Option value={0}>真实用户</Select.Option>
											<Select.Option value={1}>虚拟生成</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="status" label="状态" initialValue="">
										<Select>
											<Select.Option value="">全部</Select.Option>
											<Select.Option value={0}>未领取</Select.Option>
											<Select.Option value={1}>已领取</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="winningTime" label="抽奖时间" initialValue="">
										<RangePicker/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Button type="primary" htmlType="submit">
										查询
									</Button>
									<Button
										style={{ margin: '0 8px' }}
										onClick={() => {
											recordWinninForm.resetFields()
										}}
									>
										清空
									</Button>
								</Col>
							</Row>
						</Form>
						<Table
							size="small"
							rowKey={(record) => record.id}
							dataSource={recordWinningData.list}
							columns={recordWinnColumns}
							pagination={{
								current: recordWinningData.current,
								total: recordWinningData.total,
								showTotal: (total) => `共${total}条`,
								showTitle: false,
								showSizeChanger: false,
								onChange: handlerecordWinnChangePage,
							}}
						/>
					</TabPane>
				</Tabs>
			</Modal>
		</div>
	)
}
