import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  PageHeader,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  message,
  Modal,
  Affix,
  Radio
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  uploadfile,
  deleteFile,
  createFishMessage,
  updateFishMessage,
  fishMessage,
  getFishCategory,
  getMapImgByLng,

} from '@/https/FishManage'
import './index.scss'
import AMapLoader from '@amap/amap-jsapi-loader'

const { Search } = Input

export default function Page() {
  const { TextArea } = Input
  const { operate, id } = useParams()
  const [form] = Form.useForm()
  const homeContentRef = useSelector((state) => state.home.AffixRef)
  const districtData = useSelector((state) => state.District.districtData)
  const navigate = useNavigate()
  let map = useRef(null)
  let AMapRef = useRef(null)
  let markerRef = useRef(null)
  let districtRef = useRef(null)
  let placeSearchRef = useRef(null)
  let polygonsRef = useRef([])
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  }
  const maxCover = 10

  const [fishCategoryData, setFishCategoryData] = useState('')
  const [detailData, setDetailData] = useState({})
  const [coverList, setCoverList] = useState([])
  const [imgList, setImgList] = useState([])
  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewTitle, setPreviewTitle] = useState('')
  const [provinceList, setProvinceList] = useState('')
  const [cityList, setCityList] = useState('')
  const [waitSureData, setWaitSureData] = useState({})
  const [placeSearcList, setPlaceSearcList] = useState('')
  const [originDetailsData,setOriginDetailsData] = useState({})

  // 审核模态框
  const [checkVisible, setCheckVisible] = useState(false)
  const [needRule,setNeedRule] = useState(false)
  const [checkLoaing,setCheckLoaing] = useState(false)
  const [checkForm] = Form.useForm()

  useEffect(() => {
    checkForm.validateFields(['rule']);
  }, [needRule, checkForm]);


  useEffect(() => {
    if (districtData) {
      setProvinceList(districtData)
    }
  }, [districtData])

  // 更换省份
  const handleProvinceChange = (id, item) => {
    if (id) {
      const { key, children } = item
      setCityList(provinceList[key].cityList)
      form.setFieldsValue({ cityId: '', areaId: '' })
      getAdministrative(children, 'province')
    }
  }

  // 获取鱼种
  const getFishCategoryList = useCallback(async () => {
    const { data } = await getFishCategory()
    if (data.code === '00000') {
      setFishCategoryData(data.data)
    }
  }, [])

  useEffect(() => {
    getFishCategoryList()
  }, [getFishCategoryList])

  // 坐标转地址
  const transitionData = useCallback((lnglat) => {
    const geocoder = new AMapRef.current.Geocoder({})
    geocoder.getAddress(lnglat, (status, result) => {
      if (status === 'complete' && result.regeocode) {
        setWaitSureData({ ...result.regeocode, lnglat })
        let address = result.regeocode.formattedAddress
        form.setFieldsValue({ address: address })
        markerRef.current.setLabel({
          direction: 'top',
          offset: new AMapRef.current.Pixel(10, 0),  //设置文本标注偏移量
          content: '<div class=\'address_info\'>' + address + '</div>' //设置文本标注内容q
        })
      } else {
        message.warning('根据经纬度查询地址失败')
      }
    })
  }, [form])

  // 点击获取坐标
  const clickHandler = useCallback((e) => {
    const lnglat = [e.lnglat.getLng(), e.lnglat.getLat()]
    map.current.add(markerRef.current)
    markerRef.current.setPosition(lnglat)
    transitionData(lnglat)
  }, [transitionData])

  // 行政区查询
  const getAdministrative = (addrName, level) => {
    if (!districtRef.current) {
      const opts = {
        subdistrict: 0,   //获取边界不需要返回下级行政区
        extensions: 'all',  //返回行政区边界坐标组等具体信息
        level: 'district'  //查询行政级别为 市
      }
      districtRef.current = new AMapRef.current.DistrictSearch(opts)
    }

    districtRef.current.setLevel(level)
    districtRef.current.search(addrName, (status, result) => {
      map.current.remove(polygonsRef.current)//清除上次结果
      polygonsRef.current = []
      const bounds = result.districtList[0].boundaries
      if (bounds) {
        for (let i = 0, l = bounds.length; i < l; i++) {
          //生成行政区划polygon
          const polygon = new AMapRef.current.Polygon({
            strokeWeight: 1,
            path: bounds[i],
            fillOpacity: 0.4,
            fillColor: '#80d8ff',
            strokeColor: '#0091ea'
          })
          polygonsRef.current.push(polygon)
        }
      }
      map.current.add(polygonsRef.current)
      map.current.setFitView(polygonsRef.current)//视口自适应
      const removeTime = setTimeout(() => {
        map.current.remove(polygonsRef.current)
        clearTimeout(removeTime)
      }, 1000)
    })
  }

  // 搜索
  const onSearchBtn = (val) => {
    placeSearchRef.current = new AMapRef.current.PlaceSearch({
      pageIndex: 1, // 页码
      pageSize: 5,
      map: map.current, // 展现结果的地图实例
      // panel: "panel", // 结果列表将在此容器中进行展示。
      autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
    })  //构造地点查询类
    placeSearchRef.current.search(val, (status, result) => {
      console.log('status, result :>> ', status, result)
      if (status === 'complete' && result.info === 'OK') {
        setPlaceSearcList(result.poiList)
      }
    })
  }

  // 选择检索出来的地址
  const checkPlace = (item) => {
    console.log('item :>> ', item)
    const {
      lng,
      lat
    } = item.location
    const lnglat = [lng, lat]
    // 重新标记
    placeSearchRef.current.clear()
    markerRef.current.setPosition(lnglat)
    map.current.setCenter(lnglat)
    transitionData(lnglat)
  }

  // 加载地图
  const getAMap = useCallback((ll) => {
    let lnglat = ll || [114.058435, 22.543063]
    AMapLoader.load({
      key: '2f84cbab118a2537c74c8fb46ad2df60',                     // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0',              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.CitySearch', 'AMap.DistrictSearch', 'AMap.ToolBar']               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      AMapRef.current = AMap
      map.current = new AMap.Map('container', { //设置地图容器id
        resizeEnable: true,
        zoom: 16,                //初始化地图级别，在PC上，默认为[3,18]，取值范围[3-18]；在移动设备上，默认为[3,19],取值范围[3-19]
        center: lnglat //初始化地图中心点位置
      })

      const toolbar = new AMap.ToolBar()
      map.current.addControl(toolbar)

      // 坐标点
      markerRef.current = new AMap.Marker({
        position: map.current.getCenter(),
        icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        offset: new AMap.Pixel(-13, -30),
        // 设置是否可以拖拽
        draggable: true,
        cursor: 'move',
        // 设置拖拽效果
        raiseOnDrag: true
      })

      // 鼠标点击
      map.current.on('click', clickHandler)

      if (ll) {
        markerRef.current.setMap(map.current)
        transitionData(lnglat)
      }
      // // 标记拖拽定位
      markerRef.current.on('dragend', (e) => {
        const { lat, lng } = e.lnglat
        lnglat = [lng, lat]
        transitionData(lnglat)
      })
    })
  }, [clickHandler, transitionData])

  // 生成静态地图
  const onCreateMapImg = () => {
    if (!waitSureData.lnglat) {
      message.warning('请先选择地址')
      return
    }
    const [lng, lat] = waitSureData.lnglat
    getMapImgByLng({ lat, lng }).then(({ data }) => {
      if (data.code === '00000') {
        message.success('生成地址成功')
        form.setFieldsValue({ mapImg: data.data })
      }
    })
  }

  // 查看地图图片
  const handlePreviewMapImg = () => {
    const mapImage = form.getFieldValue('mapImg')
    if (mapImage) {
      setPreviewImage(mapImage)
      setPreviewTitle('地图')
      setPreviewVisible(true)
    } else {
      message.warning('请先生成静态图片')
    }
  }

  // 查询详情
  const getDetail = useCallback(async () => {
    if (id && provinceList) {
      const { data } = await fishMessage(id)
      if (data.code === '00000') {
        provinceList.map(item => {
          if (item.id === data.data.provinceId) {
            setCityList(item.cityList)
          }
          return true
        })
        const lnglat = data.data.lng ? [data.data.lng,data.data.lat] : null
        setWaitSureData({ lnglat })
        const formData = onNeatenData(data.data)
        setOriginDetailsData(data.data)
        setDetailData(formData)
        form.setFieldsValue(formData)
        getAMap(lnglat)
      }
    }
  }, [id, form, provinceList, getAMap])

  useEffect(() => {
    if (id * 1) {
      getDetail()
    } else {
      getAMap()
    }
  }, [id, getDetail, getAMap])

  // 图片表单数据处理
  const onNeatenData = (data) => {
    let converForm = [], imgForm = []
    if (data.homeImg) {
      const coverArr = data.homeImg.split(',')
      coverArr.map((item, i) => {
        converForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
      setCoverList(converForm)
    }
    if (data.imgList) {
      const coverArr = data.imgList.split(',')
      coverArr.map((item, i) => {
        imgForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
      setImgList(imgForm)
    }
    const {
      name,
      type,
      way,
      category,
      payType,
      payStandard,
      fishNumber,
      cardNumber,
      cardFlag,
      principal,
      phone,
      area,
      depth,
      description,
      rule,
      provinceId,
      cityId,
      mapImg,
      address
    } = data
    return ({
      name,
      type,
      way,
      category: category ? category.split(',') : [],
      payType,
      payStandard,
      fishNumber,
      cardNumber,
      cardFlag,
      principal,
      phone,
      area,
      depth,
      description,
      rule,
      provinceId,
      cityId,
      mapImg,
      address,
      homeImg: { fileList: converForm },
      imgList: { fileList: imgForm }
    })
  }

  const onFinish = async () => {
    const formData = await form.validateFields()
    console.log('Received values of form: ', formData)
    console.log('waitSureData :>> ', waitSureData)
    const { lnglat } = waitSureData
    const [lng, lat] = lnglat
    let coverData = [], imgData = []
    formData?.homeImg?.fileList &&
    formData.homeImg.fileList.map((item) => {
      coverData.push(item.url)
      return true
    })
    formData?.imgList?.fileList &&
    formData.imgList.fileList.map((item) => {
      imgData.push(item.url)
      return true
    })
    const category = formData.category.join(',')
    const params = {
      ...formData,
      homeImg: coverData.join(','),
      imgList: imgData.join(','),
      lng,
      lat,
      category
    }
    if (operate === 'edit') {
      params.id = id * 1
      console.log('params :>> ', params)
      const { data } = await updateFishMessage(params)
      if (data.code === '00000') {
        message.success('保存成功')
        navigate(-1)
      } else {
        message.error(data.message)
      }
    } else {
      console.log('params :>> ', params)
      const { data } = await createFishMessage(params)
      if (data.code === '00000') {
        message.success('保存成功')
        navigate(-1)
      } else {
        message.error(data.message)
      }
    }
  }

  // 显示审核模态框
  const handleShowCheckModal = () => {
    setCheckVisible(true)
    setNeedRule(false)
    checkForm.resetFields()
  }
  // 确定审核
  const handleCheckSubmit =async (values) => {
    console.log('values', values)
    setCheckLoaing(true)
    const params = {
      id:id * 1,
      status:values.status
    }
    if(values.status === 2) params.rule = values.rule
    const {data} = await updateFishMessage(params)
    if(data.code === '00000'){
      message.success('审核成功')
      navigate(-1)
    }else {
      message.error(data.message)
    }

  }

  // 图片文件处理
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewTitle(file.name)
    setPreviewVisible(true)
  }

  const handleCoverChange = ({ fileList }) => {
    setCoverList(fileList)
  }

  const handleImgChange = ({ fileList }) => {
    setImgList(fileList)
  }

  // 上传图片
  const upLoadFile = async (u) => {
    const { uid } = u.file
    let formData = new FormData()
    formData.append('file', u.file)
    const { data } = await uploadfile(formData)
    if (data.code === '00000') {
      coverList.filter((info) => {
        if (info.uid === uid) {
          info.url = data.data
          info.status = 'done'
        }
        return true
      })
      setCoverList([...coverList])
      message.success('上传成功')
    } else {
      coverList.filter((info) => {
        if (info.uid === uid) {
          info.status = 'error'
        }
        return true
      })
      setCoverList([...coverList])
      message.error(data.message)
    }
  }

  const upLoadImgList = async (u) => {
    const { uid } = u.file
    let formData = new FormData()
    formData.append('file', u.file)
    const { data } = await uploadfile(formData)
    if (data.code === '00000') {
      imgList.filter((info) => {
        if (info.uid === uid) {
          info.url = data.data
          info.status = 'done'
        }
        return true
      })
      setImgList([...imgList])
      message.success('上传成功')
    } else {
      imgList.filter((info) => {
        if (info.uid === uid) {
          info.status = 'error'
        }
        return true
      })
      setImgList([...imgList])
      message.error(data.message)
    }
  }

  // 删除图片
  const removeFile = async (file) => {
    const { url, status } = file
    if (status === 'error') {
      return true
    }
    const { data } = await deleteFile({ filePath: url })
    if (data.code === '00000') {
      message.success('删除成功')
      return true
    } else {
      message.error(data.message)
      return false
    }
  }

  return (
    <div>
      <Affix target={() => homeContentRef} offsetTop={0}>
        <PageHeader
          className="public_page_header"
          onBack={() => window.history.back()}
          title={
            operate === 'add'
              ? '新增'
              : operate === 'edit'
                ? '编辑'
                : '查看'
          }
          extra={[
            originDetailsData.status === 1 &&
            <Button type="primary" onClick={() => handleShowCheckModal()}>
              审核
            </Button>,
            operate !== 'look' && (
              <Button type="primary" onClick={onFinish}>
                保存
              </Button>
            )
          ]}
        />
      </Affix>
      <div className="public_post_form">
        <Form
          labelWrap
          {...layout}
          form={form}
          initialValues={
            operate === 'add'
              ? ''
              : detailData
          }
        >
          <Form.Item
            name="name"
            label="钓场名称"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled={operate === 'look' ? true : false}/>
          </Form.Item>
          <Form.Item name="type" label="类别" initialValue={0}>
            <Select disabled={operate === 'look' ? true : false}>
              <Select.Option value={0}>钓场</Select.Option>
              <Select.Option value={1}>渔具店</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="way" label="作钓方式">
            <Input disabled={operate === 'look' ? true : false}/>
          </Form.Item>
          <Form.Item name="category" label="鱼种">
            <Select mode="tags" disabled={operate === 'look' ? true : false}>
              {
                fishCategoryData && fishCategoryData.map(item => {
                  return (
                    <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name="payType" label="收费标准" initialValue={-1}>
            <Select disabled={operate === 'look' ? true : false}>
              <Select.Option value={-1}>免费</Select.Option>
              <Select.Option value={0}>计时</Select.Option>
              <Select.Option value={1}>记重</Select.Option>
              <Select.Option value={2}>混合</Select.Option>
              <Select.Option value={3}>套餐</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="payStandard" label="收费标准描述">
            <Input disabled={operate === 'look' ? true : false}/>
          </Form.Item>
          <Form.Item name="fishNumber" label="钓位数量">
            <InputNumber
              disabled={operate === 'look' ? true : false}
            />
          </Form.Item>
          <Form.Item name="cardNumber" label="车位数量">
            <InputNumber
              disabled={operate === 'look' ? true : false}
            />
          </Form.Item>
          <Form.Item name="cardFlag" label="是否方便停车" initialValue={0}>
            <Select disabled={operate === 'look' ? true : false}>
              <Select.Option value={0}>是</Select.Option>
              <Select.Option value={1}>否</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="principal" label="负责人">
            <Input disabled={operate === 'look' ? true : false}/>
          </Form.Item>
          <Form.Item name="phone" label="负责人电话">
            <Input disabled={operate === 'look' ? true : false}/>
          </Form.Item>
          <Form.Item name="area" label="水域面积">
            <InputNumber
              disabled={operate === 'look' ? true : false}
            />
          </Form.Item>
          <Form.Item name="depth" label="最高水深">
            <InputNumber
              disabled={operate === 'look' ? true : false}
              addonAfter={<span>米</span>}
              stringMode
            />
          </Form.Item>
          <Form.Item name="description" label="钓场简介">
            <TextArea
              rows={4}
              disabled={operate === 'look' ? true : false}
            />
          </Form.Item>
          <Form.Item name="rule" label="描述">
            <TextArea
              rows={4}
              disabled={operate === 'look' ? true : false}
            />
          </Form.Item>
          <Form.Item name="provinceId" label="省" initialValue="">
            <Select onChange={handleProvinceChange}>
              <Select.Option value="">请选择省份</Select.Option>
              {
                provinceList && provinceList.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name="cityId" label="市" initialValue="">
            <Select onChange={(id, item) => {
              const { children } = item
              getAdministrative(children, 'city')
            }}>
              <Select.Option value="">请选择市</Select.Option>
              {
                cityList && cityList.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item label="地图">
            <div className="map_body">
              <div className="map_search_box">
                <Search
                  placeholder="检索地址"
                  allowClear
                  enterButton="查询"
                  size="large"
                  onSearch={onSearchBtn}
                />
              </div>
              <div className="map_search_list">
                {
                  placeSearcList?.pois?.length && placeSearcList.pois.map(item => {
                    return (
                      <div className="map_search_list_place" key={item.id} onClick={() => checkPlace(item)}>
                        <p title={item.name}><b>{item.name}</b></p>
                        <p title={item.address}>地址：{item.address}</p>
                      </div>
                    )
                  })
                }
              </div>
              <div id="container" style={{ height: '100%' }}>
              </div>
            </div>
          </Form.Item>
          <Form.Item name="mapImg" label="静态地图" initialValue="">
            <Input
              className="map_img_input"
              placeholder="静态地图"
              disabled={true}
              addonAfter={
                <div className="map_img_btns">
                  {
                    operate === 'look' ? '' : <span className="map_img_generate" onClick={onCreateMapImg}>生成静态图</span>
                  }
                  <span className="map_img_look" onClick={handlePreviewMapImg}>查看图片</span>
                </div>
              }
            />
          </Form.Item>
          <Form.Item name="address" label="地址">
            <Input disabled={operate === 'look' ? true : false}/>
          </Form.Item>
          <Form.Item name="homeImg" label="封面图">
            <Upload
              disabled={operate === 'look' ? true : false}
              customRequest={upLoadFile}
              listType="picture-card"
              fileList={coverList}
              onPreview={handlePreview}
              onChange={handleCoverChange}
              onRemove={removeFile}
            >
              {coverList.length >= maxCover ? null : (
                <div>
                  <PlusOutlined/>
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item name="imgList" label="图片集合">
            <Upload
              disabled={operate === 'look' ? true : false}
              customRequest={upLoadImgList}
              listType="picture-card"
              fileList={imgList}
              onPreview={handlePreview}
              onChange={handleImgChange}
              onRemove={removeFile}
            >
              {imgList.length >= maxCover ? null : (
                <div>
                  <PlusOutlined/>
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </div>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt="example"
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
      <Modal
        visible={checkVisible}
        title="审核"
        footer={null}
        onCancel={() => setCheckVisible(false)}
      >
        <Form
          labelWrap
          labelCol={{span:6}}
          wrapperCol={{ span: 18 }}
          form={checkForm}
          initialValues={
            {
              status: 0,
              rule: ''
            }
          }
          onFinish={handleCheckSubmit}
        >
          <Form.Item
            name="name"
            label="钓场名称"
            rules={[
              {
                required: false
              }
            ]}
          >
            {originDetailsData.name}
          </Form.Item>
          <Form.Item
            name="status"
            label="状态"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Radio.Group name='status' onChange={e => setNeedRule(e.target.value === 2)}>
              <Radio value={0}>通过</Radio>
              <Radio value={2}>不通过</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="rule"
            label="备注"
            rules={[
              {
                required: needRule
              }
            ]}
          >
            <TextArea
              name='rule'
              placeholder="审核不通过的备注"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{offset:10}}>
            <Button type="primary" htmlType="submit" loading={checkLoaing}>
             确定
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </div>
  )
}
