import axios from 'axios'
import encryptMdRsa from './encrypt'
import { Modal } from 'antd'
import Qs from 'qs'

export const myAxios = (option) => {
  const encryptData = encryptMdRsa(option)
  const Authorization = sessionStorage.getItem("Authorization");
  let defaultOption = {
    method: 'post',
    transformRequest: [
      function (data) {
        data = JSON.stringify(data);
        return data
      },
    ],
    headers: {
      'Content-Type': 'application/json',
    },
  }
  if(option.method === "get"){
    defaultOption = {
      method: 'get',
      transformRequest: [
        function (data) {
          data = Qs.stringify(data)
          return data
        },
      ],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }
  }
  const options = {
    ...defaultOption,
    dataType: "json",
    credentials: 'include',
    ...option,
  }
  options.headers = { ...options.headers, ...encryptData, Authorization }
  return axios(options)
}

/**
 * 批量请求接口
 * @param funcs 请求方法
 * @returns {*}
 */
export const axiosAll = (funcs) => {
  return axios.all(funcs)
  .then(function (data) {
    return data
  });
}

axios.interceptors.response.use(respons => {
  const { code, message } = respons.data
  if(code === "A0300"){
    Modal.warning({
        title: '提示',
        content: message,
        okText: '退出，重新登陆',
        onOk: function () {
            sessionStorage.removeItem("Authorization");
            window.location.href = '/';
        }
    })
    return respons;
  }else if (respons.status  === 505) {
      window.location.href = '/';
  } else if (respons.status  === 508) {
      window.location.href = '/';
  } else if (respons.status === 507) {
      Modal.warning({
          title: '提示',
          content: '该帐号正在另一处登陆成功，请确认当前操作人。同一帐号不可以多人同时登陆！',
          okText: '退出，重新登陆',
          onOk: function () {
              window.location.href = '/';
          }
      })
      return respons;
  } else {
    return respons ;
  }
}, (error) => {
  console.log('xhr error',error)
  if(error.request){
    const response = JSON.parse(error.request.response)
    if(response.code === 'A0300'){
      sessionStorage.removeItem("Authorization");
      window.location.href = '/';
    }
    return response
  }else{
    Modal.warning({
        title: '提示',
        content: error,
        okText: '退出，重新登陆',
        onOk: function () {
            sessionStorage.removeItem("Authorization");
            window.location.href = '/';
        }
    })
  }
})
