import React, { useState, useEffect, useCallback } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	Select,
	InputNumber,
	DatePicker,
	message,
	Upload,
	Modal,
	Affix,
	Table,
	Row,
	Col,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	commodityList,
	uploadfile,
	deleteFile,
	addOrUpdateLottery,
	lotteryDetails
} from '@/https/LotteryManage'
import moment from 'moment'

export default function Page() {
	const { RangePicker } = DatePicker
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const [commodityForm] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
	const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxCover = 10


	const [lotteryData, setLotteryData] = useState([{
		key: '1',
		name: '普通版',
		probability: 0,
		commodityList:[]
	},{
		key: '2',
		name: '隐藏版',
		probability: 0,
		commodityList:[]
	},{
		key: '3',
		name: '豪华版',
		probability: 0,
		commodityList:[]
	},{
		key: '4',
		name: '欧皇版',
		probability: 0,
		commodityList:[]
	}])
	// const [detailData, setDetailData] = useState('')
	const [lotteryKey, setLtteryKey] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [commodityData, setCommodityData] = useState('')
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedCommodityData, setSelectedCommodityData] = useState([]);
	const [coverList, setCoverList] = useState([])
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')

	// 查询详情
	const getDetail = useCallback(async () => {
		const { data } = await lotteryDetails(parseInt(id))
		console.log(data)
		if (data.code === '00000') {
      const formData = onNeatenData(data.data)
      form.setFieldsValue(formData)
		}
	}, [id, form])

  useEffect(() => {
		if (!!parseInt(id)) getDetail()
	}, [id, getDetail])

	// 图片表单数据处理
  const onNeatenData = (data) => {
    const {
      lottery,
      lotteryPrizes
    } = data
    const {
      title,
      startTime,
      endTime,
      price,
      defaultNum,
      goldNum,
      userWinningCeiling,
      prizeExpirationTime,
      prizeExpirationType,
      cardExpirationTime,
      cardExpirationType,
      shareCover,
      shareTitle,
      shareRemark
    } = lottery
    let converForm = []
    if(shareCover){
      const coverArr = shareCover.split(',')
      coverArr.map((item, i)=>{
        converForm.push({
          uid: i,
          url: item,
          status: 'done'
        })
        return true
      })
      setCoverList(converForm)
    }
    let activityTime = []
    if(startTime && endTime){
      activityTime = [moment(moment(startTime).format('YYYY-MM-DD'), 'YYYY-MM-DD'), moment(moment(endTime).format('YYYY-MM-DD'), 'YYYY-MM-DD')]
    }
    let newLotteryPrizes = {}

		lotteryPrizes.map(item => {
			newLotteryPrizes['sonprobability_' + (item.level + 1) + '_' + item.commodityId] = item.probability
			if(newLotteryPrizes['probability_' + (item.level + 1)]){
				newLotteryPrizes['probability_' + (item.level + 1)] += item.probability
			} else {
				newLotteryPrizes['probability_' + (item.level + 1)] = item.probability
			}
			lotteryData[item.level].commodityList.push({
				id: item.commodityId,
				name: item.commodityName,
				sellPrice: item.sellPrice,
				cover: item.cover
			})
			return true
		})

		setLotteryData([...lotteryData])

    return({
      title,
      activityTime,
			price,
      defaultNum,
      goldNum,
      userWinningCeiling,
      prizeExpiration: {
        prizeExpirationTime,
        prizeExpirationType
      },
      cardExpiration: {
        cardExpirationTime,
        cardExpirationType
      },
      shareTitle,
      shareRemark,
      shareCover: {fileList: converForm},
			...newLotteryPrizes
    })
  }

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleCoverChange = ({ fileList }) => {
		setCoverList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setCoverList([...coverList])
			message.success('上传成功')
		} else {
			coverList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setCoverList([...coverList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
    console.log(file)
		const { url, status, response } = file
    let filePath = url
		if (status === 'error') {
			return true
		}
    if(!url && response.code !== "00000"){
      return true
    }
    if(!url){
      filePath = response.data
    }
		const { data } = await deleteFile({ filePath })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

	// 选择商品
	const showCommodityModal = (key) => {
		setLtteryKey(key)
		let commodityIds = []
		lotteryData[key].commodityList.map(item => {
			commodityIds.push(item.id)
			return true
		})
		setSelectedRowKeys([...commodityIds])
		setSelectedCommodityData(lotteryData[key].commodityList)
		setIsModalVisible(true)
		getCommodityList()
	}

	// 商品列表
  const getCommodityList = (param) => {
    let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
    commodityList(params).then(({ data }) => {
			if (data.code === '00000') {
				setCommodityData(data.data)
			}
		})
  }

  // 查询商品
  const onCommodityFinish = (values) => {
    getCommodityList(values)
  }

  /**
	 * 分页
	 * @param page
	 */
	const handleCommodityChangePage = (page) => {
		const data = { current: page }
		getCommodityList(data)
	}

	const handleOk = () => {
		lotteryData[lotteryKey].commodityList = [...selectedCommodityData]
		setLotteryData([...lotteryData])
		setIsModalVisible(false)
		setSelectedRowKeys([])
		setSelectedCommodityData([])
	}

	const handleCancel = () => {
		setSelectedRowKeys([])
		setSelectedCommodityData([])
		setIsModalVisible(false)
	}

  // 选择商品
	const rowSelection = {
		selectedRowKeys,
		onChange: (newSelectedRowKeys, newSelectedRows) => {
			let commodityIds = []
			commodityData.list.map(item => {
				commodityIds.push(item.id)
				return true
			})
			let differenceRowKeys = selectedRowKeys.filter(info => !commodityIds.includes(info))
			setSelectedRowKeys([...differenceRowKeys, ...newSelectedRowKeys])
			let differenceRows = selectedCommodityData.filter(info => !commodityData.list.filter(item => item.id === info.id).length)
			setSelectedCommodityData([...differenceRows, ...newSelectedRows])
		},
		getCheckboxProps: (record) => ({
			disabled: lotteryData.filter(info => info.commodityList.filter(item => item.id === record.id).length).length,
		}),	
	}

	// 删除选择的商品
	const handleDel = (index) => {
		const delCommodityList = lotteryData[lotteryKey].commodityList
		delCommodityList.splice(index, 1)
		lotteryData[lotteryKey].commodityList = [...delCommodityList]
		setLotteryData([...lotteryData])
		onChangeCalculate(lotteryData[lotteryKey].key)
	}

	// 商品
	const commodityColumns = [
		{
			title: '商品名称',
			dataIndex: 'name',
			key: 'name',
		},
	]

	// 提交
	const onFinish = async () => {
		const formData = await form.validateFields()
		console.log('Received values of form: ', formData)
		const {
      title,
      activityTime,
      price,
      defaultNum,
      goldNum,
      userWinningCeiling,
      prizeExpiration,
      cardExpiration,
      shareCover,
      shareTitle,
      shareRemark,
    } = formData
		let coverData = []
		shareCover?.fileList &&
			shareCover.fileList.map((item) => {
				item.url && coverData.push(item.url)
				return true
			})
		let lotteryPrizes =[], totalProbability = 0
		lotteryData.map((item, i) => {
			item.commodityList.map(info => {
				totalProbability += formData['sonprobability_' + item.key + '_' + info.id] || 0
				lotteryPrizes.push({
					level: i,
					commodityId: info.id,
					commodityName: info.name,
					category: info.type,
					probability: formData['sonprobability_' + item.key + '_' + info.id] || 0
				})
				return true
			})
			return true
		})
		if(totalProbability > 100){
			message.warning('抽奖概率大于100%')
			return
		}else if(totalProbability < 100){
			message.warning('抽奖概率小于100%')
			return
		}
		let params = {
      lottery: {
				type: 1,
        title,
        price,
        defaultNum,
        goldNum,
        userWinningCeiling,
        ...prizeExpiration,
        ...cardExpiration,
        shareCover: coverData.join(','),
        shareTitle,
        shareRemark,
      },
      lotteryPrizes
		}
    if(activityTime){
      let [startTime, endTime] = activityTime
      params.lottery.startTime = moment(startTime).format('YYYY-MM-DD') + ' 00:00:00'
      params.lottery.endTime = moment(endTime).format('YYYY-MM-DD') + ' 23:59:59'
    }
    if(operate === 'edit'){
      params.lottery.id = id*1
    }
    console.log('params---',params)
    const { data } = await addOrUpdateLottery(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 概率变动监听计算
	const onChangeCalculate = (key) => {
		let ids = []
		let lotteryIndex = parseInt(key) - 1
		let total = 0;
		lotteryData[lotteryIndex].commodityList && lotteryData[lotteryIndex].commodityList.length && lotteryData[lotteryIndex].commodityList.map(item => {
			ids.push(item.id)
			return item
		})
		ids.length && ids.map(item => {
			total += form.getFieldValue('sonprobability_' + key + '_' + item)
			return item
		})
		let probabilityObj = {}
		probabilityObj['probability_' + key] = total
		form.setFieldsValue(probabilityObj)
	}

	// 展示表格图片
	const showImgs = (record) => {
		const urlArr = record.cover.split(',')
		let html = urlArr.map(item => {
			return <img style={{'height': '26px', 'cursor': 'pointer'}} src={item} alt="" onClick={() => openImg(item, record)}/>
		})
		return html
	}

	// 打开图片
	const openImg = (url, record) => {
		setPreviewImage(url)
		setPreviewTitle(record.name + '商品图片')
		setPreviewVisible(true)
	}

	const columns = [
    {
			title: '抽奖名称',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
			render: (text, record) => <Form.Item
				name={'name' + record.key}
				initialValue={text}
			>
				<Input disabled={true}/>
			</Form.Item>
		},
    {
      title: '抽奖概率',
      dataIndex: 'probability',
      key: 'probability',
			render: (text, record) => <Form.Item
				name={'probability_' + record.key}
				initialValue={text}
			>
				<InputNumber
					max={100}
					formatter={value => `${value}%`}
					precision={3}
					disabled={true}
				/>
			</Form.Item>
    },
		{
      title: '已选商品数量',
      dataIndex: 'commodityList',
      key: 'commodityNum',
			render: (text) => <span>{text ? text.length : '0'}</span>
    },
    {
      title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 100,
      render: (text, record, index) =>  <span>
				<Button
					type="primary"
					size="small"
					onClick={() => showCommodityModal(index)}
					disabled={operate === 'look' ? true : false}
				>
					添加商品
				</Button>
			</span>,
    },
  ];

	const sonColumns = [
		{
			title: '商品名称',
			dataIndex: 'name',
			key: 'name',
			width: 360,
			fixed: 'left',
		},
		{
			title: '图片',
			dataIndex: 'cover',
			key: 'cover',
			render: (d, record) => <div>
				{showImgs(record)}
			</div>,
		},
		{
			title: '售价',
			dataIndex: 'sellPrice',
			key: 'sellPrice',
			width: 150
		},
    {
      title: '抽奖概率',
      dataIndex: 'probability',
      key: 'probability',
			width: 160,
			fixed: 'right',
			render: (text, record) => <Form.Item
				name={'sonprobability_' + record.key + '_' + record.id}
				initialValue={0}
			>
				<InputNumber
					max={100}
					formatter={value => `${value}%`}
					precision={3}
					disabled={operate === 'look' ? true : false}
					onChange={() => onChangeCalculate(record.key)}
				/>
			</Form.Item>
    },
    {
      title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 100,
      render: (text, record, index) =>  <span>
				<Button
					type="primary"
					danger
					size="small"
					onClick={() => handleDel(index)}
					disabled={operate === 'look' ? true : false}
				>
					删除商品
				</Button>
			</span>,
    },
	]

	const expandedRowRender = (item) => {
		const data = item.commodityList.map(list => {
			list.key = item.key
			return list
		});
		return <Table columns={sonColumns} rowKey={(record) => record.id} dataSource={data} scroll='max-content' pagination={false} size='small' bordered/>;
	}

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => window.history.back()}
					title={
						operate === 'add'
							? '新增'
							: operate === 'edit'
							? '编辑'
							: '查看'
					}
					extra={[
						operate !== 'detail' && (
							<Button type="primary" onClick={onFinish}>
								保存
							</Button>
						),
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form
					{...layout}
					form={form}
					initialValues={
						operate === 'add' ? '' : ''
					}
				>
					<Form.Item
						name="title"
						label="抽奖名称"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input disabled={operate === 'look' ? true : false} />
					</Form.Item>
					<Form.Item name="price" label="售价">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="activityTime" label="抽奖时间">
						<RangePicker
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="defaultNum" label="每天抽奖次数">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="goldNum" label="一次金币数量">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item name="userWinningCeiling" label="用户中奖上限">
						<InputNumber
							disabled={operate === 'look' ? true : false}
						/>
					</Form.Item>
					<Form.Item label="实物奖品领取时效">
						<Input.Group compact>
							<Form.Item
								name={['prizeExpiration', 'prizeExpirationTime']}
								noStyle
							>
								<InputNumber disabled={operate === 'look' ? true : false}/>
							</Form.Item>
							<Form.Item
								name={['prizeExpiration', 'prizeExpirationType']}
								noStyle
								initialValue={0}
							>
								<Select disabled={operate === 'look' ? true : false}>
									<Select.Option value={0}>小时</Select.Option>
									<Select.Option value={1}>天</Select.Option>
									<Select.Option value={2}>月</Select.Option>
								</Select>
							</Form.Item>
						</Input.Group>
					</Form.Item>
					<Form.Item label="虚拟卡卷领取时效">
						<Input.Group compact>
							<Form.Item
								name={['cardExpiration', 'cardExpirationTime']}
								noStyle
							>
								<InputNumber disabled={operate === 'look' ? true : false}/>
							</Form.Item>
							<Form.Item
								name={['cardExpiration', 'cardExpirationType']}
								noStyle
								initialValue={0}
							>
								<Select disabled={operate === 'look' ? true : false}>
									<Select.Option value={0}>小时</Select.Option>
									<Select.Option value={1}>天</Select.Option>
									<Select.Option value={2}>月</Select.Option>
								</Select>
							</Form.Item>
						</Input.Group>
					</Form.Item>
					<Form.Item name="shareCover" label="分享封面">
						<Upload
							disabled={operate === 'look' ? true : false}
							customRequest={upLoadFile}
							listType="picture-card"
							fileList={coverList}
							onPreview={handlePreview}
							onChange={handleCoverChange}
							onRemove={removeFile}
						>
							{coverList.length >= maxCover ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item
						name="shareTitle"
						label="分享标题"
					>
						<Input disabled={operate === 'look' ? true : false} />
					</Form.Item>
					<Form.Item
						name="shareRemark"
						label="分享备注"
					>
						<Input disabled={operate === 'look' ? true : false} />
					</Form.Item>
					<Table 
						className='table_form_list'
						columns={columns} 
						dataSource={lotteryData} 
						scroll='max-content'
						pagination={false}
						expandable={{
							expandedRowRender,
							defaultExpandAllRows: true
						}}		
					/>
				</Form>
			</div>
			<Modal
				title="商品"
				centered
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Form
					form={commodityForm}
					className="public_search_box"
					onFinish={onCommodityFinish}
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item name={`name`}>
								<Input placeholder="商品名称" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Button type="primary" htmlType="submit">
								查询
							</Button>
							<Button
								style={{ margin: '0 8px' }}
								onClick={() => {
									commodityForm.resetFields()
								}}
							>
								清空
							</Button>
						</Col>
					</Row>
				</Form>
				<Table
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						...rowSelection,
					}}
					dataSource={commodityData.list}
					columns={commodityColumns}
          scroll={{ x: 'max-content' }}
          pagination={{
						current: commodityData.current*1 || 1,
						total: commodityData.total*1,
						showTotal: (total) => `共${total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleCommodityChangePage,
					}}
				/>
			</Modal>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	)
}
