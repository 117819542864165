import React, { useState, useEffect } from 'react'
import {
	Form,
	Row,
	Col,
	Input,
	Button,
	Table,
	Select,
	message,
	Modal,
	Radio,
} from 'antd'
import { orderList, updateOrder } from '@/https/OrderManage'
import moment from 'moment'

export default function Page() {
	const [form] = Form.useForm()

	const [loading, setLoading] = useState('false')
	const [orderData, setOrderData] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [updateData, setUpdateData] = useState('')
	const [orderStatus, setOrderStatus] = useState(0)
  const [loadingStatus, setLoadingStatus] = useState(false)

	useEffect(() => {
		getList()
	}, [])

	const getList = async (param) => {
		setLoading(true)
		let option = {
			current: 1,
			pageSize: 10,
		}
		let params = { ...option, ...param }
		const { data } = await orderList(params)
		setLoading(false)
		if (data.code === '00000') {
			setOrderData(data.data)
		}
	}

	/**
	 * 分页
	 * @param page
	 */
	const handleChangePage = (page) => {
		const params = form.getFieldsValue()
		const data = { current: page, ...params }
		getList(data)
	}

	const onFinish = (values) => {
		getList(values)
	}

	// 更新订单状态
	const handleStatus = (record) => {
		setUpdateData({...record})
		setOrderStatus(record.orderStatus || 0)
		setIsModalVisible(true)
	}

	const onChange = (e) => {
		setOrderStatus(e.target.value)
	}

	const handleOk = async (record) => {
    setLoadingStatus(true)
		const { data } = await updateOrder({
			id: updateData.id,
			orderStatus: orderStatus,
		})
    setLoadingStatus(false)
		if (data.code === '00000') {
			message.success('更新成功')
			getList()
      setIsModalVisible(false)
		} else {
			message.error(data.message)
		}
	}

	const columns = [
		{
			title: '订单编号',
			dataIndex: 'orderCode',
			key: 'orderCode',
			fixed: 'left',
		},
		{
			title: '订单类别',
			dataIndex: 'orderType',
			key: 'orderType',
			render: (d) => <span>{d === 0 ? '虚拟卡券' : '实物'}</span>,
		},
		{
			title: '快递单号',
			dataIndex: 'courierNo',
			key: 'courierNo',
		},
		{
			title: '快递公司',
			dataIndex: 'courierCompany',
			key: 'courierCompany',
		},
		{
			title: '物流状态',
			dataIndex: 'courierStatus',
			key: 'courierStatus',
			render: (d) => <span>{d === 0 ? '待发货' : '已完成'}</span>,
		},
		{
			title: '发货时间',
			dataIndex: 'deliveryTime',
			key: 'deliveryTime',
			render: (d) => <span>{d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : '---'}</span>,
		},
		{
			title: '订单状态',
			dataIndex: 'orderStatus',
			key: 'orderStatus',
			render: (d) => (
				<span>
					{d === 0
						? '待发货（虚拟券未核销）'
						: d === 1
						? '已发货'
						: d === 2
						? '已取消'
						: d === 3
						? '待收货'
						: d === 4
						? '虚拟券已核销'
						: '---'}
				</span>
			),
		},
		{
			title: '快递公司编号',
			dataIndex: 'courierCode',
			key: 'courierCode',
		},
		{
			title: '操作',
			key: 'operation',
			fixed: 'right',
			width: 200,
			render: (text, record, index) => (
				<span>
					<Button
						type="primary"
						danger
						size="small"
						onClick={() => handleStatus(record)}
					>
						更新订单状态
					</Button>
				</span>
			),
		},
	]

	return (
		<div className="public_content_box">
			<Form form={form} className="public_search_box" onFinish={onFinish}>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item name="orderCode">
							<Input placeholder="订单编号" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="orderType"
							label="订单类别"
							initialValue=""
						>
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>
									虚拟卡券
								</Select.Option>
								<Select.Option value={1}>实物</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="courierStatus"
							label="物流状态"
							initialValue=""
						>
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>待发货</Select.Option>
								<Select.Option value={1}>已完成</Select.Option>
							</Select>
						</Form.Item>
					</Col>
          <Col span={8}>
						<Form.Item
							name="orderStatus"
							label="订单状态"
							initialValue=""
						>
							<Select>
								<Select.Option value="">全部</Select.Option>
								<Select.Option value={0}>待发货（虚拟券未核销）</Select.Option>
								<Select.Option value={1}>已发货</Select.Option>
                <Select.Option value={2}>已取消</Select.Option>
								<Select.Option value={3}>待收货</Select.Option>
                <Select.Option value={4}>虚拟券已核销</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							查询
						</Button>
						<Button
							style={{ margin: '0 8px' }}
							onClick={() => {
								form.resetFields()
							}}
						>
							清空
						</Button>
					</Col>
				</Row>
			</Form>
			<div className="public_table">
				<Table
					loading={loading}
					dataSource={orderData.list}
					columns={columns}
					rowKey={(record) => record.id}
					locale={{ emptyText: '暂无数据' }}
					scroll={{ x: 'max-content' }}
					pagination={{
						current: orderData.current,
						total: orderData.total,
						showTotal: (total) => `共${orderData.total}条`,
						showTitle: false,
						showSizeChanger: false,
						onChange: handleChangePage,
					}}
				/>
			</div>
			<Modal
				title="更新订单状态"
				centered
        width={650}
				visible={isModalVisible}
        confirmLoading={loadingStatus}
        maskClosable={false}
				onOk={handleOk}
				onCancel={() => setIsModalVisible(false)}
			>
				<Radio.Group onChange={onChange} value={orderStatus}>
					<Radio value={0}>待发货（虚拟券未核销）</Radio>
					<Radio value={1}>已发货</Radio>
					<Radio value={2}>已取消</Radio>
					<Radio value={3}>待收货</Radio>
					<Radio value={4}>虚拟券已核销</Radio>
				</Radio.Group>
			</Modal>
		</div>
	)
}
