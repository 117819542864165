const init = {
    AffixRef: null
}

const homeReducer = (state = init, action) => {
    switch (action.type) {
        case 'AffIXREF':
            return {...state,AffixRef : action.data}
        default:
            return state
    }
}

export default homeReducer