import React from 'react'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import './index.scss'
import { loginToken } from '@/https/Login'

export default function Login() {
	const navigate = useNavigate()

	const onFinish = (values) => {
		loginToken(values).then((res) => {
			const { data } = res
			if (data.code === '00000') {
        sessionStorage.setItem('Authorization', data.data.token_type + " " +  data.data.access_token)
				navigate('/home/ActivityManage')
			}
		})
	}

	return (
		<div className="login_body">
			<div className="login_modal">
				<h1 className="login_tit">管理门户登录</h1>
				<Form onFinish={onFinish}>
					<Form.Item name="username" initialValue="">
						<Input prefix={<UserOutlined />} placeholder="用户名" />
					</Form.Item>
					<Form.Item name="password" initialValue="">
						<Input.Password
							prefix={<LockOutlined />}
							type="password"
							placeholder="密码"
						/>
					</Form.Item>
					<Form.Item>
						<Input placeholder="验证码" style={{ width: '52%' }} />
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="login-form-button"
							style={{ height: '40px' }}
							block
						>
							登录
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}
