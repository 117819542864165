import React, { useState, useEffect, useCallback } from 'react'
import {
	PageHeader,
	Button,
	Form,
	Input,
	Upload,
	message,
	Modal,
	Affix,
	Cascader,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	uploadfile,
	deleteFile,
	skillClassUpdate,
	skillClassAdd,
	skillGetClassList,
} from '@/https/SkillManage'

export default function Page() {
	const { TextArea } = Input
	const { operate, id } = useParams()
	const [form] = Form.useForm()
	const homeContentRef = useSelector((state) => state.home.AffixRef)
	const detailData = useSelector((state) => state.SkillManage.SkillDetail)
  const navigate = useNavigate()
	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	}
	const maxIcon = 10

	const [classOptions, setClassOptions] = useState([])
	const [cascaderValue, setCascaderValue] = useState([0])
	const [iconList, setIconList] = useState([])
	const [previewImage, setPreviewImage] = useState('')
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')

	// 分类数组拼接
	const classJoint = useCallback((initData, pid, level) => {
		let reachedLevel = initData.filter(
			(info) => info.pid === pid && info.level === level
		)
		let nextLevel = initData.filter((info) => info.level > level)
		if (nextLevel.length > 0) {
			reachedLevel.map((item) => {
				let children = classJoint(nextLevel, item.id, level + 1)
				if (children.length) {
					item.children = children
				}
				return true
			})
		}
		return reachedLevel
	}, [])

	// 查询分类
	useEffect(() => {
    // 获取父级分类的value
    const filtrationClassId = (classAll,classIds) => {
      for(let i = 0; i < classAll.length; i++){
        let item = classAll[i]
        if(item.id === classIds[0]){
          if(item.pid !== 0){
            classIds.unshift(item.pid)
            filtrationClassId(classAll,classIds)
          }else{
            console.log(classIds)
            classIds.unshift(0)
            setCascaderValue([...classIds])
            break;
          }
        }
      }
    }
    
    const getClassList = async () => {
      const { data } = await skillGetClassList({})
      if (data.code === '00000' && data.data.length) {
        if(detailData)filtrationClassId(data.data, [detailData.pid])
        const classArr = classJoint(data.data, 0, 1)
        setClassOptions([
          { name: '零级分类', id: 0, level: 0, children: classArr },
        ])
      }
    }
		getClassList()
	}, [classJoint, detailData])

	const onChange = (value) => {
		setCascaderValue(value)
	}

	const onNeatenData = (data) => {
		let iconForm = []
		if (data.icon) {
			const iconArr = data.icon.split(',')
			iconArr.map((item, i) => {
				iconForm.push({
					uid: i,
					url: item,
					status: 'done',
				})
				return true
			})
		}
    const {parent, name, classDescribe} = data
		return {
			parent,
      name,
      classDescribe,
			icon: { fileList: iconForm },
		}
	}

	const onFinish = async () => {
		const formData = await form.validateFields()
		console.log('Received values of form: ', formData)
		let iconData = []
		formData?.icon?.fileList &&
			formData.icon.fileList.map((item) => {
				iconData.push(item.url)
				return true
			})
		const level = cascaderValue.length
		const pid = cascaderValue[cascaderValue.length - 1]
		const params = {
			...formData,
			pid: pid || 0,
			level: level,
			icon: iconData.join(','),
		}
		let postFun
		if (operate === 'add') {
			postFun = skillClassAdd
		} else {
			postFun = skillClassUpdate
			params.id = id * 1
		}
		const { data } = await postFun(params)
		if (data.code === '00000') {
			message.success('保存成功')
      navigate(-1)
		} else {
			message.error(data.message)
		}
	}

	// 图片文件处理
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewTitle(file.name)
		setPreviewVisible(true)
	}

	const handleIconChange = ({ fileList }) => {
		setIconList(fileList)
	}

	// 上传图片
	const upLoadFile = async (u) => {
		const { uid } = u.file
		let formData = new FormData()
		formData.append('file', u.file)
		const { data } = await uploadfile(formData)
		if (data.code === '00000') {
			iconList.filter((info) => {
				if (info.uid === uid) {
					info.url = data.data
					info.status = 'done'
				}
				return true
			})
			setIconList([...iconList])
			message.success('上传成功')
		} else {
			iconList.filter((info) => {
				if (info.uid === uid) {
					info.status = 'error'
				}
				return true
			})
			setIconList([...iconList])
			message.error(data.message)
		}
	}

	// 删除图片
	const removeFile = async (file) => {
		const { url, status } = file
		if (status === 'error') {
			return true
		}
		const { data } = await deleteFile({ filePath: url })
		if (data.code === '00000') {
			message.success('删除成功')
			return true
		} else {
			message.error(data.message)
			return false
		}
	}

	return (
		<div>
			<Affix target={() => homeContentRef} offsetTop={0}>
				<PageHeader
					className="public_page_header"
					onBack={() => window.history.back()}
					title={
						operate === 'add'
							? '新增'
							: operate === 'edit'
							? '编辑'
							: '查看'
					}
					extra={[
						operate !== 'detail' && (
							<Button type="primary" onClick={onFinish}>
								保存
							</Button>
						),
					]}
				/>
			</Affix>
			<div className="public_post_form">
				<Form
					labelWrap
					{...layout}
					form={form}
					initialValues={
						operate === 'add' ? '' : onNeatenData(detailData)
					}
				>
					<Form.Item
						name="parent"
						label="父级分类"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Cascader
							disabled={operate === 'detail' ? true : false}
              fieldNames={{ label: 'name', value: 'id', children: 'children' }}
							value={cascaderValue}
							options={classOptions}
							onChange={onChange}
							changeOnSelect
							expandTrigger="hover"
						/>
						,
					</Form.Item>
					<Form.Item
						name="name"
						label="分类名称"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input disabled={operate === 'detail' ? true : false} />
					</Form.Item>
					<Form.Item name="icon" label="图标">
						<Upload
							disabled={operate === 'detail' ? true : false}
							customRequest={upLoadFile}
							listType="picture-card"
							fileList={iconList}
							onPreview={handlePreview}
							onChange={handleIconChange}
							onRemove={removeFile}
						>
							{iconList.length >= maxIcon ? null : (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item name="classDescribe" label="描述">
						<TextArea
							rows={4}
							disabled={operate === 'detail' ? true : false}
						/>
					</Form.Item>
				</Form>
			</div>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</div>
	)
}
