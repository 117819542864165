import {
  myAxios as axios
} from '../index'

/**
 * 获取省市区
 * @param params
 * @returns {*}
 */
export const getAllRegions = (params) => {
  return axios({
    method: 'get',
    url: `/naerdiao/district/getAllRegions`,
    params
  })
}