import {
  myAxios as axios
} from '../index'
import Qs from 'qs'

/**
 * 活动列表
 * @param params
 * @returns {*}
 */
export const activityList = (params) => {
  return axios({
    url: `/naerdiao/activity/list`,
    data: params
  })
}

/**
 * 上传文件
 * @param params
 * @returns {*}
 */
export const uploadfile = (params) => {
  return axios({
    url: `/naerdiao/file/uploadfile?check=naerdiao`,
    data: params,
    transformRequest: [
      function (data) {
        return data
      },
    ]
  })
}

/**
 * 删除图片
 * @param params
 * @returns {*}
 */
export const deleteFile = (params) => {
  return axios({
    url: `/naerdiao/file/deleteFile`,
    data: params,
    transformRequest: [
      function (data) {
        data = Qs.stringify(data)
        return data
      },
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

/**
 * 商品列表
 * @param params
 * @returns {*}
 */
export const commodityList = (params) => {
  return axios({
    url: `/naerdiao/commodity/list`,
    data: params
  })
}

/**
 * 钓场列表
 * @param params
 * @returns {*}
 */
export const fishList = (params) => {
  return axios({
    url: `/naerdiao/fishMessage/getPage`,
    data: params
  })
}

/**
 * 新增活动列表
 * @param params
 * @returns {*}
 */
export const activityAdd = (params) => {
  return axios({
    url: `/naerdiao/activity/add`,
    data: params
  })
}

/**
 * 修改活动列表
 * @param params
 * @returns {*}
 */
export const activityUpdate = (params) => {
  return axios({
    url: `/naerdiao/activity/update`,
    data: params
  })
}

/**
 * 查询活动详情
 * @param params
 * @returns {*}
 */
export const activityDetails = (id) => {
  return axios({
    method: 'get',
    url: `/naerdiao/activity/${id}`
  })
}

/**
 * 查询活动参与人
 * @param params
 * @returns {*}
 */
export const userList = (params) => {
  return axios({
    url: `/naerdiao/activity/userList`,
    data: params
  })
}

/**
 * 开奖
 * @param params
 * @returns {*}
 */
 export const openAdminActivity = (params) => {
  return axios({
    url: `/naerdiao/activity/openAdminActivity`,
    data: params
  })
}